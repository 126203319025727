import { Switch, Route, MemoryRouter } from "react-router-dom";

import ReactGA from "react-ga";
import LoginPhoneNumber from "./views/login/loginPhoneNumber";
import LoginOTP from "./views/login/loginOTP";
import LoginLanguage from "./views/login/loginLanguage";
import LoginTC from "./views/login/loginTC";
import LoginBlocked from "./views/login/loginBlocked";
import LoginWait from "./views/login/loginWait";
import Data from "./views/data/data";
import Tickets from "./views/tickets/tickets";
import Home from "./views/home/home";
import LoginSplash from "./views/login/loginSplash";
import Storage from "./storage";
import "./App.scss";
import NewTicketType from "./views/tickets/newTicketType";
import NewTicketTC from "./views/tickets/newTicketTC";
import NewTicketSubject from "./views/tickets/newTicketSubject";
import NewTicketEmail from "./views/tickets/newTicketEmail";
import NewTicketConfirm from "./views/tickets/newTicketConfirm";
import NewTicketRange from "./views/tickets/newTicketRange";
import NewTicketDataAccess from "./views/tickets/newTicketDataAccess";
import NewTicketDefault from "./views/tickets/newTicketDefault";
import { useSelector } from "react-redux";
import { store } from "./index";
import { setShowLogout } from "./store/actions";

function handleLogoutFinalClick() {
  Storage.setSession(null);
  window.location.href = "/";
}

function handleCloseLogoutClick() {
  store.dispatch(setShowLogout(false));
}

function renderLogout(dictionary: any) {
  return (
    <div className="row home-menu-logout-background">
      <div className="row home-logout-container">
        <div className="row justify-content-center home-logout-title">{dictionary?.homeMenu?.logout}</div>
        <div className="row justify-content-center home-logout-text">{dictionary?.homeMenu?.logoutText}</div>
        <button className="row justify-content-center home-logout-button-logout" onClick={handleLogoutFinalClick}>
          {dictionary?.homeMenu?.logout}
        </button>
        <button className="row justify-content-center home-logout-button-cancel" onClick={handleCloseLogoutClick}>
          {dictionary?.homeMenu?.logoutCancel}
        </button>
      </div>
    </div>
  );
}

function App() {
  const toastText = useSelector((state: any) => state.toastText);
  const showLogout = useSelector((state: any) => state.showLogout);
  const dictionary = useSelector((state: any) => state.selectedDictionary);

  if (!Storage.getActiveLanguage()) Storage.setActiveLanguage("pt");
  ReactGA.initialize("UA-590893-02");
  return (
    <MemoryRouter>
      {toastText.length > 0 ? <div className="main-toast">{toastText}</div> : ""}
      {showLogout ? renderLogout(dictionary) : ""}
      {/*
          A <Switch> looks through all its children <Route>
          elements and renders the first one whose path
          matches the current URL. Use a <Switch> any time
          you have multiple routes, but you want only one
          of them to render at a time
        */}
      <Switch>
        <Route exact path="/">
          <LoginSplash />
        </Route>
        <Route path="/loginphonenumber">
          <LoginPhoneNumber />
        </Route>
        <Route path="/loginotp/:error">
          <LoginOTP />
        </Route>
        <Route path="/logintc">
          <LoginTC />
        </Route>
        <Route path="/loginlanguage">
          <LoginLanguage />
        </Route>
        <Route path="/loginblocked/:type/:blockedtimeleft">
          <LoginBlocked />
        </Route>
        <Route path="/loginwait/:type">
          <LoginWait />
        </Route>
        <Route path="/data">
          <Data />
        </Route>
        <Route path="/tickets">
          <Tickets />
        </Route>
        <Route path="/newtickettype">
          <NewTicketType />
        </Route>
        <Route path="/newtickettc">
          <NewTicketTC />
        </Route>
        <Route path="/newticketsubject">
          <NewTicketSubject />
        </Route>
        <Route path="/newticketrange">
          <NewTicketRange />
        </Route>
        <Route path="/newticketemail">
          <NewTicketEmail />
        </Route>
        <Route path="/newticketdataaccess">
          <NewTicketDataAccess />
        </Route>
        <Route path="/newticketdefault">
          <NewTicketDefault />
        </Route>
        <Route path="/newticketconfirm/:status">
          <NewTicketConfirm />
        </Route>
        <Route path="/home">
          <Home />
        </Route>
      </Switch>
    </MemoryRouter>
  );
}

export default App;
