import React from "react";

import spinnerIcon from "../assets/spinner.svg";

import "./button.scss";

interface ButtonProps {
  className: string;
  text: string;
  disabled?: boolean;
  loading?: boolean;
  loadingText?: string;
  onClick: () => void;
}

export const Button: React.FC<ButtonProps> = ({ className, text, disabled, loading, loadingText, onClick }) => {

  function render() {
      return (
        <button className={className} disabled={disabled || loading} onClick={onClick}>
          {loading ? 
          <span>
            <img src={spinnerIcon} className="icon-loading" alt="spinner" /> 
            <label>{loadingText || text}</label>
          </span> :
          <label>{text}</label>}
        </button>
      );
  }

  return render();
};


export default Button; 
