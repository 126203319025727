import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { HomeTopMenu } from "../home/homeTopMenu";
import { HomeMenu } from "../home/homeMenu";
import { useMediaQuery } from "react-responsive";

import "react-datepicker/dist/react-datepicker.css";
import "./tickets.scss";
import CategoriesPicker from "../data/categoriesPicker";
import { Requests } from "../../requests";
import { DataDatePicker } from "../data/dataDatePicker";
import { TicketType } from "./ticketType";
import { TicketsList } from "./ticketsList";
import TicketDetail from "./ticketDetail";
import Menu, { MenuItems } from "../home/menu";
import TicketDetailDesktop from "./ticketDetailDesktop";
import { Aux } from "../../models";
import { Button } from "../../components/button";

export const Tickets: React.FC = () => {
  const dictionary = useSelector((state: any) => state.selectedDictionary);

  const statusList = React.useCallback(() => {
    let list: { ids: string[]; name: string; selected: boolean }[] = [];
    if (dictionary && dictionary.tickets && dictionary.tickets.statusList) {
      let listFromText = JSON.parse(dictionary?.tickets?.statusList);      
      list = listFromText?.reduce((acc: any, cur: any) => {
        let filteredByName = acc.filter((e: any) => e.name === cur.name);
        if(filteredByName.length > 0) {
          filteredByName[0].ids.push(cur.id);
        } else {
          acc.push({ ids: [ cur.id ], name: cur.name });
        }
        return acc;
      }, []);
    }
    return list;
  }, [dictionary]);

  const dateNow = new Date(),
  y = dateNow.getFullYear(),
  m = dateNow.getMonth();

  const [startDate, setStartDate] = React.useState<Date>(new Date(y, m - 1, 1, 0, 0, 0));
  const [endDate, setEndDate] = React.useState<Date>(new Date());
  const [ticketsAll, setTicketsAll] = React.useState<[]>();
  const [tickets, setTickets] = React.useState<[]>([]);
  const [selectedTicket, setSelectedTicket] = React.useState<any>(null);

  const [showMenu, setShowMenu] = React.useState<boolean>(false);
  const [showTicketDetail, setShowTicketDetail] = React.useState<boolean>(false);
  const [ticketStatusList, setTicketStatusList] = React.useState<{ ids: string[]; name: string; selected: boolean }[]>(statusList());
  const [ticketTypeList, setTicketTypeList] = React.useState<[any]>();
  const [ticketType, setTicketType] = React.useState<string>("");
  const [loadingTickets, setLoadingTickets] = React.useState<boolean>();
  const isDesktop = useMediaQuery({ query: "(min-width: 600px)" });

  const history = useHistory();

  React.useEffect(() => {        
    Requests.getTicketsTypes()
      .then((responseTypes: any) => {
        setTicketTypeList(responseTypes.types);
        setTicketType(responseTypes.types[0].type);
        
        Requests.getTickets(startDate, endDate, 0, Config.TicketsMaxCountRequest, [])
          .then((response: any) => {
            setTicketsAll(response.tickets);
          }).catch((ex: any) => {
            Aux.setToastText(dictionary?.errors.generic);
            Aux.handleErrors(history, ex, dictionary);
          });
      }).catch((ex: any) => {
        Aux.setToastText(dictionary?.errors.generic);
        Aux.handleErrors(history, ex, dictionary);
      });
    setTicketStatusList(statusList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusList, dictionary, history]);

  React.useEffect(() => {
    let filterItems = ticketsAll?.filter((x: any) => x.type === ticketType);
    setTickets(filterItems as []);
  }, [ticketType, ticketsAll]);

  function onChangeDates(startDateFromDatePicker: Date, endDateFromDatePicker: Date) {
    setStartDate(startDateFromDatePicker);
    setEndDate(endDateFromDatePicker);
    let filterStatus = ticketStatusList.filter((x) => x.selected === true).flatMap((x) => x.ids);
    setLoadingTickets(true);
    Requests.getTickets(startDateFromDatePicker, endDateFromDatePicker, 0, Config.TicketsMaxCountRequest, filterStatus as [string])
      .then((response: any) => {
        setTicketsAll(response.tickets);
        setLoadingTickets(false);
      }).catch((ex) => {
        Aux.setToastText(dictionary?.errors.generic);
        Aux.handleErrors(history, ex, dictionary);
        setLoadingTickets(false);
      });
  }

  function handleNewTicketClick() {
    history.push("/newTicketType");
  }

  function onTicketStatusClick() {
    const tempArray = ticketStatusList.slice();
    setTicketStatusList(tempArray);
    onChangeDates(startDate, endDate);
  }

  function renderMobile() {
    return (
      <div>
        <div>
          <HomeMenu show={showMenu} onCloseClick={setShowMenu}></HomeMenu>
        </div>
        <div>
          <TicketDetail ticket={selectedTicket} typeList={ticketTypeList} show={showTicketDetail} onCloseClick={setShowTicketDetail}></TicketDetail>
        </div>
        <div>
          <HomeTopMenu showWhite={true} onMenuClick={setShowMenu} title={dictionary?.tickets?.title}></HomeTopMenu>
          <div className="container">
            <div className="row">
              <DataDatePicker
                startDateText={dictionary?.data?.startDateText}
                endDateText={dictionary?.data?.endDateText}
                startDate={startDate}
                endDate={endDate}
                onChangeDates={(startDatePicker: Date, endDatePicker: Date) => onChangeDates(startDatePicker, endDatePicker)}
              ></DataDatePicker>
            </div>
            <div className="row">
              <CategoriesPicker categories={ticketStatusList} onItemClick={onTicketStatusClick} />
            </div>
            <div className="row">
              <TicketType
                typeList={ticketTypeList as [any]}
                loading={loadingTickets}
                onItemClick={(id: string) => {
                  setTicketType(id);
                }}
              ></TicketType>
            </div>
            <div className="row justify-content-center">
              <Button className="secondary-button tickets-new" text={dictionary?.tickets.newTicket} onClick={handleNewTicketClick}></Button>
            </div>
          </div>
          <div className="row tickets-items-container">
            <TicketsList
              typeList={ticketTypeList as [any]}
              tickets={tickets}
              loading={loadingTickets}
              onItemClick={(ticket: any) => {
                setSelectedTicket(ticket);
                setShowTicketDetail(true);
              }}
            ></TicketsList>
          </div>
        </div>
      </div>
    );
  }

  function renderDesktop() {
    return (
      <div className="container">
        <div className={showTicketDetail ? "tickets-detail-back-container-show" : "tickets-detail-back-container-hide"}></div>
        <div className="row main-row">
          {/* {toastText.length > 0 ? <div className="dataCategories-toast">{toastText}</div> : ""} */}
          <div className="support-data-left-div">
            <Menu selected={MenuItems.Tickets}></Menu>
          </div>
          <div className={showTicketDetail ? "tickets-main-div-small" : "tickets-main-div-full"}>
            <div className="container">
              <div className="support-data-container">
                <div className="row justify-content-center">
                  <div className="tickets-top-container">
                    <span className="tickets-title">{dictionary?.tickets?.title}</span>
                    <Button className="secondary-button tickets-new" text={dictionary?.tickets.newTicket} onClick={handleNewTicketClick}></Button>
                  </div>
                </div>
                <div className="row justify-content-center tickets-datepicker">
                  <DataDatePicker
                    startDateText={dictionary?.data?.startDateText}
                    endDateText={dictionary?.data?.endDateText}
                    startDate={startDate}
                    endDate={endDate}
                    onChangeDates={(startDatePicker: Date, endDatePicker: Date) => onChangeDates(startDatePicker, endDatePicker)}
                  ></DataDatePicker>
                </div>
                <div className="row justify-content-center tickets-categoriespicker">
                  <CategoriesPicker categories={ticketStatusList} onItemClick={onTicketStatusClick} />
                </div>
                <div className="row justify-content-center">
                  <div className="tickets-tickettype">
                    <TicketType
                      typeList={ticketTypeList as [any]}
                      loading={loadingTickets}
                      onItemClick={(id: string) => {
                        setTicketType(id);
                      }}
                    ></TicketType>
                  </div>
                </div>
                <div className="row justify-content-center"></div>
                <div className="row justify-content-center tickets-items-container">
                  <TicketsList
                    typeList={ticketTypeList as [any]}
                    tickets={tickets}
                    loading={loadingTickets}
                    onItemClick={(ticket: any) => {
                      setSelectedTicket(ticket);
                      setShowTicketDetail(true);
                    }}
                  ></TicketsList>
                </div>
              </div>
            </div>
          </div>
          <div className={showTicketDetail ? "tickets-detail-container-show" : "tickets-detail-container-hide"}>
            <TicketDetailDesktop ticket={selectedTicket} typeList={ticketTypeList} onCloseClick={setShowTicketDetail}></TicketDetailDesktop>
          </div>
        </div>
      </div>
    );
  }

  function render() {
    return isDesktop ? renderDesktop() : renderMobile();
  }

  return <div className="App">{render()}</div>;
};

export default Tickets;
