import React from "react";
import "./categoriesPicker.scss";
import { DataCategory } from "../../models";

interface CategoriesPickerProps {
  categories: DataCategory[];
  onItemClick: (index: number) => void;
}

export const CategoriesPicker: React.FC<CategoriesPickerProps> = ({ categories, onItemClick }) => {

  function handleItemClick(index: number) {
    const allSelected = categories.every(c => c.selected);
    const isCategorySelected = !allSelected && categories[index].selected;
    categories.forEach((category, i) => {
      category.selected = !isCategorySelected && (i === index);
    });
    onItemClick(index);
  }

  function renderItems() {
    const noneSelected = categories.every(c => !c.selected);
    const listItems = categories.map((item: DataCategory, index: number) => (
      <div
        key={index}
        className={
          `${index === 0 ? "data-categories-container-item-1 " : ""}data-categories-container-item${noneSelected || item.selected ? "-selected" : ""}`}
        onClick={() => handleItemClick(index)}
      >
        <div className="data-categories-container-item-text">{item.name}</div>
      </div>
    ));
    return listItems;
  }

  function render() {
    return <div className="data-categories-container">{renderItems()}</div>;
  }

  return render();
};

export default CategoriesPicker;
