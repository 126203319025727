import React from "react";
import { useSelector } from "react-redux";
import "./ticketType.scss";

interface TicketTypeProps {
  typeList: [any];
  loading: boolean|undefined;
  onItemClick: (id: string) => void;
}

export const TicketType: React.FC<TicketTypeProps> = ({ typeList, loading, onItemClick }) => {
  const dictionary = useSelector((state: any) => state.selectedDictionary);
  const [type, setType] = React.useState<number>(0);

  function handleItemClick(index: number) {
    setType(index);
    onItemClick(typeList[index].type);
  }

  React.useEffect(() => {
  }, [dictionary]);

  function render() {
    return (
      <div className="btn-group ticket-type-container">
        {loading ? null : typeList?.map((item: any, index: number) => (
          <button key={`ticketTypeButton${index}`} type="button" className={type === index ? "ticket-type-item-selected" : "ticket-type-item"} onClick={() => handleItemClick(index)}>
            {item.name[dictionary?.type]}
          </button>
        ))}
      </div>
    );
  }

  return render();
};

export default TicketType;
