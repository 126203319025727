import React from "react";
import { useSelector } from "react-redux";
import "./dataDatePicker.scss";
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import pt from "./locale/pt";
import en from "date-fns/locale/en-US";

import rightArrow from "./../../assets/right-arrow.svg";

const DATE_PICKER_FORMAT = "dd/MMMM/yyyy";

interface DatePickerProps {
  startDateText: string;
  endDateText: string;
  startDate?: Date;
  endDate?: Date;
  onChangeDates: (startDatePicker: Date, endDatePicker: Date) => void;
}

export const DataDatePicker: React.FC<DatePickerProps> = (props: DatePickerProps) => {
  const dateNow = new Date(),
    y = dateNow.getFullYear(),
    m = dateNow.getMonth();

  const [startDate, setStartDate] = React.useState(props.startDate || new Date(y, m, 1, 0, 0, 0));
  const [endDate, setEndDate] = React.useState(props.endDate || new Date());
  const dictionary = useSelector((state: any) => state.selectedDictionary);
  registerLocale("pt", pt);
  registerLocale("en", en);

  React.useEffect(() => {
    props.onChangeDates(startDate, endDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate]);

  function render() {
    return (
      <div className=" data-date-picker">
        <div className="date-picker-left">
          <div className="date-picker-title">{props.startDateText}</div>
          <DatePicker
            locale={dictionary?.type}
            dateFormat={DATE_PICKER_FORMAT}
            selected={startDate}
            onChange={(date: any) => setStartDate(date)}
            maxDate={new Date()}
            className="data-date-datePicker data-date-datePicker-left"
            onFocus={(e) => e.target.blur()}
          />
        </div>
        <div className="date-picker-middle">
          <img src={rightArrow} alt="Logo" className="date-picker-arrow" />
        </div>
        <div className="date-picker-right">
          <div className="date-picker-title">{props.endDateText}</div>
          <DatePicker
            locale={dictionary?.type}
            dateFormat={DATE_PICKER_FORMAT}
            selected={endDate}
            onChange={(date: any) => {
              if (date > startDate) setEndDate(date);
            }}
            minDate={startDate}
            maxDate={new Date()}
            className="data-date-datePicker data-date-datePicker-right"
            onFocus={(e) => e.target.blur()}
          />
        </div>
      </div>
    );
  }

  return render();
};

export default DataDatePicker;
