import React from "react";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import Select from "react-select";
import { Button } from "../../components/button";

import mainLogoRed from "../../assets/logos-vodafone-red.svg";
import menuCloseWhite from "../../assets/icons-close-red.svg";
import menuBack from "../../assets/icons-arrow-arrow-left.svg";
import "./newTicket.scss";
import "./newTicketDefault.scss";

import { useHistory } from "react-router-dom";
import Menu, { MenuItems } from "../home/menu";
import Storage from "../../storage";
import Requests from "../../requests";
import { Aux } from "../../models";
import { TicketType, DEFAULT_TICKET_ITEM, TICKET_SUBJECT_MAX_LENGTH, TICKET_SUMMARY_MAX_LENGTH } from "../../model/tickets";

export const NewTicketDefault: React.FC = () => {
  const dictionary = useSelector((state: any) => state.selectedDictionary);
  const isProductAndServices = Storage.getNewTicketType() === TicketType.ProductsAndServices;
  const ticketTypeObject = Storage.getNewTicketTypeObject();
  const [sendButtonLoading, setSendButtonLoading] = React.useState<boolean>(false);
  const [sendButtonDisabled, setSendButtonDisabled] = React.useState<boolean>(Storage.getNewTicketDescription()?.length === 0);
  const subjectElement = React.useRef<HTMLInputElement>(null);
  const summaryElement = React.useRef<HTMLTextAreaElement>(null);
  const selectedIndex = React.useRef<number>(getSelectedIndex());
  const [selectedValue, setSelectedValue] = React.useState<string>(getSelectedValue());
  const [textAreaCount, setTextAreaCount] = React.useState<number>(Storage.getNewTicketDescription()?.length || 0);
  const isDesktop = useMediaQuery({ query: "(min-width: 600px)" });
  const history = useHistory();

  function getSubjectOptions() {
    let list: { value: string; label: string }[] = [];
    if (dictionary && dictionary.tickets && dictionary.tickets.newTicketPS?.subjectValues) {
      let listFromText = JSON.parse(dictionary.tickets.newTicketPS?.subjectValues);
      listFromText?.forEach((element: { value: string; label: string }) => {
        list.push({ value: element.value, label: element.label });
      });
    }
    return list;
  }

  function getSelectedIndex(): number {
    let storedSubject = Storage.getNewTicketSubject() as string;
    let subjectsArray = getSubjectOptions();
    let foundIndex = subjectsArray.findIndex((x) => x.value === storedSubject);
    if (foundIndex >= 0) return foundIndex;
    else return 0;
  }

  function getSelectedValue(): string {
    let storedSubject = Storage.getNewTicketSubject() as string;
    if (storedSubject && storedSubject.length > 0) return storedSubject;
    else return getSubjectOptions()[0].value;
  }

  function getSelectedText(): string {
    let result = selectedValue;
    if (dictionary && dictionary.tickets && dictionary.tickets.newTicketPS?.subjectValues) {
      let listFromText = JSON.parse(dictionary.tickets.newTicketPS?.subjectValues);
      listFromText?.forEach((element: { value: string; label: string }) => {
        if(selectedValue === element.value) {
          result = element.label;
        }
      });
    }
    return result;
  }

  React.useEffect(() => {
    let description = Storage.getNewTicketDescription() as string;
    if (description && description.length > 0 && summaryElement && summaryElement.current) summaryElement.current.value = description;
  }, []);

  React.useEffect(() => {
    window.history.pushState(null, "", window.location.pathname);
    window.onpopstate = () => {
      history.goBack();
    };
  }, [history]);

  function onSubjectChange(e: any) {
    setSendButtonDisabled(subjectElement.current?.value.length === 0 ||
      summaryElement.current?.value.length === 0);
    
    if (e.target.value.length > TICKET_SUBJECT_MAX_LENGTH) {
      e.target.value = e.target.value.slice(0, TICKET_SUBJECT_MAX_LENGTH);
    }
  }

  function onSummaryChange(e: any) {
    setSendButtonDisabled(subjectElement.current?.value.length === 0 ||
      summaryElement.current?.value.length === 0);
    
    if (e.target.value.length > TICKET_SUMMARY_MAX_LENGTH) {
      e.target.value = e.target.value.slice(0, TICKET_SUMMARY_MAX_LENGTH);
    }
    setTextAreaCount(e.target.value.length);
  }

  function handleCloseClick() {
    history.push("/tickets");
  }

  function handleSendClick() {
    setSendButtonLoading(true);

    Storage.setNewTicketSubject(isProductAndServices ? selectedValue : subjectElement.current?.value as string);
    if (summaryElement && summaryElement.current) Storage.setNewTicketDescription(summaryElement.current.value);

    let initialGroup = Config.TicketInitialGroup;
    let type = Storage.getNewTicketType() as string;
    let item = isProductAndServices ? getSelectedValue() : DEFAULT_TICKET_ITEM as string;
    let summary = isProductAndServices ? getSelectedText() : subjectElement.current?.value as string;
    let requestDescription = Storage.getNewTicketDescription() as string;

    Requests.newTicket(initialGroup, type, item, summary, requestDescription)
      .then((response: any) => {
        Storage.setNewTicketRequestId(response.ticketId);
        process.nextTick(() => {
          setSendButtonLoading(false);
        });
        Storage.setNewTicketType("");
        Storage.setNewTicketTypeObject(null);
        Storage.setNewTicketEmail("");
        Storage.setNewTicketSubject("");
        Storage.setNewTicketRange("");
        Storage.setNewTicketDescription("");

        history.push("/newticketconfirm/success");
      }).catch((ex: any) => {
        setSendButtonLoading(false);
        let reason = ex.response?.data?.reason;
        let errorMessage = dictionary?.errors.generic;
        if (reason && dictionary?.tickets.errors[reason]) {
          errorMessage = dictionary?.tickets.errors[reason];
        }
        Aux.setToastText(errorMessage);
      });
  }

  function renderMobile() {
    return (
      <div className="container">
        <div className="row">
          <div className="newticket-top-menu-left">
            <img src={mainLogoRed} alt="Logo" className="newticket-top-menu-logo" />
          </div>
          <div className="newticket-top-menu-midle">{dictionary?.tickets?.newTicketType?.title}</div>
          <div className="newticket-top-menu-right">
            <img src={menuCloseWhite} alt="Logo" className="newticket-top-menu-close" onClick={handleCloseClick} />
          </div>
        </div>
        <div className="row justify-content-center">
          {isProductAndServices ?
          <Select
            isSearchable={false}
            defaultValue={getSubjectOptions()[selectedIndex.current]}
            className="newTicketDefault-dropdown"
            classNamePrefix="select"
            name="selectTime"
            options={getSubjectOptions()}
            onChange={(e: any) => setSelectedValue(e.value)}
            components={{
              IndicatorSeparator: () => null,
            }}
            theme={(theme) => ({
              ...theme,
              borderRadius: 0,
              colors: {
                ...theme.colors,
                primary25: "white",
                primary: "white",
              },
            })}
            styles={{
              option: (provided, { data, isDisabled, isFocused, isSelected }) => ({
                ...provided,
                color: isSelected ? "#e60000" : "#222222",
              }),
              control: (base) => ({
                ...base,
                border: 0,
                // This line disable the blue border
                boxShadow: "none",
              }),
              dropdownIndicator: (base: any, state: any) => ({
                ...base,
                color: "#222222",
                transition: "all .2s ease",
                transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : null,
              }),
            }}
          />
          : <input ref={subjectElement} type="text" className="newTicketDefault-subject"
              onChange={onSubjectChange} maxLength={TICKET_SUBJECT_MAX_LENGTH} />}
          <div className="newTicketDefault-labelBottom">{dictionary?.tickets?.newTicketPS?.subjectBottomLabel}</div>
        </div>
        <div className="row justify-content-center">
          <textarea
            ref={summaryElement}
            placeholder={dictionary?.tickets.newTicketPS.message}
            className="newticket-summary"
            onChange={onSummaryChange}
          ></textarea>
          <div className="newticket-summary-count">{textAreaCount}/{TICKET_SUMMARY_MAX_LENGTH}</div>
        </div>
        <div className="row justify-content-center">
          <Button className="button newTicketDefault-sendButton" 
            text={dictionary?.general?.buttons?.submit}
            disabled={sendButtonDisabled}
            loading={sendButtonLoading}
            onClick={handleSendClick}>
          </Button>
        </div>
      </div>
    );
  }

  function renderDesktop() {
    return (
      <div className="container">
        <div className="row main-row">
          <div className="support-data-left-div">
            <Menu selected={MenuItems.Tickets}></Menu>
          </div>
          <div className="support-data-main-div-full">
            <div className="row justify-content-center">
              <img
                src={menuBack}
                alt="Logo"
                className="newticket-top-menu-back"
                onClick={() => { history.goBack(); }}
              />
            </div>
            <div className="row justify-content-center">
              <div className="newticket-text">
                {isProductAndServices ?
                  dictionary?.tickets?.newTicketType?.productsServices :
                  ticketTypeObject.name[Storage.getActiveLanguage() || "en"]}
                </div>
            </div>
            <div className="row justify-content-center">
              <div className="newticket-subText">
                {isProductAndServices ?
                  dictionary?.tickets?.newTicketPS?.subText :
                  dictionary?.tickets?.newTicketDefault?.subText}
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="newTicketDefault-labelSubjectTop">{dictionary?.tickets?.newTicketPS?.subject}</div>
              {isProductAndServices ? 
              <Select
                defaultValue={getSubjectOptions()[selectedIndex.current]}
                className="newTicketDefault-dropdown"
                classNamePrefix="select"
                name="selectTime"
                options={getSubjectOptions()}
                onChange={(e: any) => setSelectedValue(e.value)}
                components={{
                  IndicatorSeparator: () => null,
                }}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 0,
                  colors: {
                    ...theme.colors,
                    primary25: "white",
                    primary: "white",
                  },
                })}
                styles={{
                  option: (provided, { data, isDisabled, isFocused, isSelected }) => ({
                    ...provided,
                    color: isSelected ? "#e60000" : "#222222",
                  }),
                  control: (base) => ({
                    ...base,
                    border: 0,
                    // This line disable the blue border
                    boxShadow: "none",
                  }),
                  dropdownIndicator: (base: any, state: any) => ({
                    ...base,
                    color: "#222222",
                    transition: "all .2s ease",
                    transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : null,
                  }),
                }}
              />
              : <input ref={subjectElement} type="text" className="newTicketDefault-subject"
                  onChange={onSubjectChange} maxLength={TICKET_SUBJECT_MAX_LENGTH} />}
              <div className="newTicketDefault-labelBottom">
                {isProductAndServices ?
                  dictionary?.tickets?.newTicketPS?.subjectBottomLabel :
                  dictionary?.tickets?.newTicketDefault?.subjectBottomLabel}
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="newticket-label-message-top">{dictionary?.tickets?.newTicketPS?.message}</div>
              <textarea
                ref={summaryElement}
                placeholder={dictionary?.tickets.newTicketPS.message}
                className="newticket-summary"
                onChange={onSummaryChange}
              ></textarea>
              <div className="newticket-summary-count">{textAreaCount}/{TICKET_SUMMARY_MAX_LENGTH}</div>
            </div>
            <div className="row justify-content-center">
              <Button className="button newTicketDefault-sendButton" 
                text={dictionary?.general?.buttons?.submit}
                disabled={sendButtonDisabled}
                loading={sendButtonLoading}
                onClick={handleSendClick}>
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  function render() {
    return isDesktop ? renderDesktop() : renderMobile();
  }

  return <div className="App">{render()}</div>;
};

export default NewTicketDefault;
