import React from "react";
import { useSelector } from "react-redux";

export const LoginFooter: React.FC = () => {
  const dictionary = useSelector((state: any) => state.selectedDictionary);

  function render() {
      return (
        <div>
          <div className="row justify-content-center">
            <div className="login-copyright">{dictionary?.general?.copyright}</div>
          </div>
          <div className="row justify-content-center">
            <div className="login-tcppf">
              <a href={Config.TermsAndConditionsLink} target="_blank" rel="noreferrer">{dictionary?.general?.termsAndConditions.label}</a>
              &nbsp;|&nbsp;
              <a href={Config.PrivacyPolicyLink} target="_blank" rel="noreferrer">{dictionary?.general?.privacyPolicy.label}</a>
              &nbsp;|&nbsp;
              <a href={Config.FAQLink} target="_blank" rel="noreferrer">{dictionary?.general?.helpFAQs}</a>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="login-recaptcha" dangerouslySetInnerHTML={{ __html: dictionary?.google?.recaptchaDisclaimer }}></div>
          </div>
        </div>
      );
  }

  return <div className="LoginFooter">{render()}</div>;
};

export default LoginFooter;
