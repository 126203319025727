import React from "react";
import mainLogo6464 from "../../assets/logo6464.png";
import "./loginSplash.scss";
import { useHistory } from "react-router-dom";
import Requests from "../../requests";
import Storage from "../../storage";
import { selectedDictionaryReducer } from "../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import { Aux, OTPValidateResponse } from "../../models";

export const LoginSplash: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const dictionary = useSelector((state: any) => state.selectedDictionary);

  React.useLayoutEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const outofband = urlParams.get("outofband");
    if (outofband && outofband.length > 0) {
      Requests.validateOutofband(outofband)
        .then((response: any) => {
          history.push("/home");
        })
        .catch((ex) => {
          Aux.setToastText(dictionary?.errors?.eInvalidToken);
          history.push("/loginphonenumber");
        });
    } else {
      let accessToken = Aux.getCookie("accessToken") as string; //Storage.getSession();
      let phone = Storage.getPhoneNumberLogin();
      let lang = Storage.getActiveLanguage();
      localStorage.clear();
      if (phone && phone != null && phone.length > 0) Storage.setPhoneNumberLogin(phone as string);
      if (lang && lang != null && lang.length > 0) {
        Storage.setActiveLanguage(lang);
        dispatch(selectedDictionaryReducer(lang));
      } else {
        Storage.setActiveLanguage("pt");
        dispatch(selectedDictionaryReducer("pt"));
      }

      Requests.checkSession(accessToken)
        .then((response: any) => {
          Requests.getProfile(accessToken)
            .then((response: any) => {
              const session: OTPValidateResponse = {
                accessToken: accessToken,
                profile: response,
                refreshToken: "",
              };
              Storage.setSession(session);
              history.push("/home");
            })
            .catch((ex) => {
              history.push("/loginphonenumber");
            });
        })
        .catch((ex) => {
          history.push("/loginphonenumber");
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  function render() {
    return (
      <div className="container">
        <div className="row justify-content-center">
          <img src={mainLogo6464} alt="Logo" className="login-logo-splash" />
        </div>
      </div>
    );
  }

  return <div className="App">{render()}</div>;
};

export default LoginSplash;
