import React from "react";
import "./rangueType.scss";

interface RangueTypeProps {
  type: number;
  type0Text: string;
  type1Text: string;
  onItemClick: (value: number) => void;
}

export const RangueType: React.FC<RangueTypeProps> = (props : RangueTypeProps) => {

  function render() {
    return (
      <div className="btn-group rangue-type-container">
        <div className={props.type === 0 ? "rangue-type-item-selected" : "rangue-type-item"} onClick={() => props.onItemClick(0)}>
          {props.type0Text}
        </div>
        <div className={props.type === 1 ? "rangue-type-item-selected" : "rangue-type-item"} onClick={() => props.onItemClick(1)}>
          {props.type1Text}
        </div>
        <div style={{clear: "both"}}></div>
      </div>
    );
  }

  return render();
};

export default RangueType;
