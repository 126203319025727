import React from "react";
import { useSelector } from "react-redux";
import { format } from "date-fns";
import { EmptyData } from "../../components/emptyData";

import "./ticketsList.scss";

interface TicketsListProps {
  typeList: any;
  tickets: any;
  loading: boolean | undefined;
  loadingItemCount?: number;
  onItemClick?: (ticket: any) => void;
}

export const TicketsList: React.FC<TicketsListProps> = (props: TicketsListProps) => {
  const dictionary = useSelector((state: any) => state.selectedDictionary);
  
  function handleItemClick(index: number) {
    if(props.onItemClick) {
      props.onItemClick(props.tickets[index]);
    }
  }

  function renderItem(c: any, index: number) {
    let status = c.status;
    if (dictionary && dictionary.tickets && dictionary.tickets.statusList) {
      let listFromText = JSON.parse(dictionary?.tickets?.statusList);
      listFromText?.forEach((element: { id: string; name: string }) => {
        if (element.id === c.status) status = element.name;
      });
    }

    let type = c.type;
    props.typeList?.forEach((element: any) => {
      if (element.type === c.type) type = element?.name[dictionary?.type];
    });

    return (
      <div key={index} className="tickets-item-container" onClick={() => handleItemClick(index)}>
        <div className="tickets-items-left">
          <div className="tickets-item-text">{c.summary}</div>
          <div className="tickets-item-subtext"> {format(new Date(c.createDate), "yyyy-MM-dd, HH:mm")}</div>
        </div>
        <div className="tickets-items-right">
          <div className="tickets-item-type">{type}</div>
          <div className={"tickets-item-status tickets-item-status-" + c.status.replace(/\s+/g, "").toLowerCase()}>{status}</div>
        </div>
      </div>
    );
  }

  function renderLoading() {
    return (
      <div className="tickets-container">
        {Array.from(Array(props.loadingItemCount).keys()).map((index: number) => (
          <div key={index} className="tickets-item-container loading">
            <div className="tickets-items-left">
              <div className="tickets-item-text loading-placeholder">&nbsp;</div>
              <div className="tickets-item-subtext loading-placeholder-secondary">&nbsp;</div>
            </div>
            <div className="tickets-items-right">
              <div className="tickets-item-type loading-placeholder">&nbsp;</div>
            </div>
          </div>
        ))}
      </div>
    );
  }

  function render() {
    if(props.loading) return renderLoading();
    if (props.tickets === undefined || props.tickets === null || props.tickets.length === 0) return <EmptyData />;
    return <div className="tickets-container">{props.tickets?.map((c: any, index: number) => (c ? renderItem(c, index) : <div></div>))}</div>;
  }

  return render();
};

TicketsList.defaultProps = {
  loadingItemCount: 7,
};

export default TicketsList;
