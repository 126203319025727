import React from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import Storage from "../../storage";
import { HomeTopMenu } from "../home/homeTopMenu";
import { HomeMenu } from "../home/homeMenu";
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import pt from "date-fns/locale/pt";
import en from "date-fns/locale/en-US";
import { useMediaQuery } from "react-responsive";

import iconSingleDay from "./../../assets/icons-calendar.svg";
import iconSingleDayPicked from "./../../assets/icons-calendar-picked.svg";
import { Line } from "react-chartjs-2";
import "chartjs-adapter-moment";
import { format } from "date-fns";

import "react-datepicker/dist/react-datepicker.css";
import "./data.scss";
import CategoriesPicker from "./categoriesPicker";
import { DataCategory, Aux } from "../../models";
import { RangueType } from "./rangueType";
import { CategoriesList } from "./categoriesList";
import { Requests } from "../../requests";
import { DataDatePicker } from "./dataDatePicker";
import { EmptyData } from "../../components/emptyData";
import Menu, { MenuItems } from "../home/menu";

type Props = {
  onClick: (data: any) => void;
};

export const Data: React.FC = () => {
  const dateNow = new Date(),
    y = dateNow.getFullYear(),
    m = dateNow.getMonth();
  const dictionary = useSelector((state: any) => state.selectedDictionary);
  const [startDate, setStartDate] = React.useState(new Date(y, m, 1, 0, 0, 0));
  const [endDate, setEndDate] = React.useState(new Date());
  const [singleDate, setSingleDate] = React.useState(new Date());
  const [showMenu, setShowMenu] = React.useState<boolean>(false);
  const [displaySingleDate, setDisplaySingleDate] = React.useState<boolean>(false);
  const history = useHistory();
  const [type, setType] = React.useState<number>(0);
  const [dataCategoriesList, setDataCategoriesList] = React.useState<DataCategory[]>([]);
  const [dataCategories, setDataCategories] = React.useState<DataCategory[]>([]);
  const [totalCategoriesSpend, setTotalCategoriesSpend] = React.useState<number>(0);
  const [totalGraphSpend, setTotalGraphSpend] = React.useState<number>(0);
  const [averageGraphSpend, setAverageGraphSpend] = React.useState<number>(0);
  const [loadingGraph, setLoadingGraph] = React.useState<boolean>();
  const [emptyData, setEmptyData] = React.useState<boolean>(false);
  const isDesktop = useMediaQuery({ query: "(min-width: 600px)" });
  registerLocale("pt", pt);
  registerLocale("en", en);
  let maxDataAmount = 0;
  let dataCategoriesListFromServer = React.useRef<any[]>([]);

  const [barData, setBarData] = React.useState({
    datasets: [
      {
        label: "Data",
        data: [{ t: new Date(2021, 0, 1, 12, 0, 0, 0).valueOf(), y: 48 }],
        borderColor: "#e60000",
        borderWidth: 2,
        fill: false,
        lineTension: 0,
        pointRadius: 0,
      },
      {
        label: "Average",
        data: [{ t: new Date(2021, 0, 1, 12, 0, 0, 0).valueOf(), y: 60 }],
        borderColor: "#007c92",
        borderWidth: 2,
        fill: false,
        lineTension: 0,
        pointRadius: 0,
        borderDash: [5, 5],
      },
    ],
  });
  // set options
  const [barOptions, setBarOptions] = React.useState(makeGraphOptions(0, maxDataAmount));

  function makeGraphOptions(graphType: number, maxDataAmountValue: number) {
    return {
      options: {
        responsive: true,
        scales: {
          x: {
            type: "time",
            distribution: "series",
            offset: true,
            ticks: {
              major: {
                enabled: true,
                fontStyle: "normal",
              },
              source: "data",
              autoSkip: true,
              autoSkipPadding: 10,
              maxRotation: 0,
              sampleSize: 100,
              labelOffset: 16,
              callback: function (value: any, index: any, values: any) {
                return format(new Date(values[index].value), graphType === 0 ? "dd MMM" : "HH");
              },
            },
            grid: {
              display: false,
              offset: true,
            },
          },
          y: {
            ticks: {
              beginAtZero: true,
              callback: function (value: any, index: any, values: any) {
                if (index === 0) return "0 Kb";
                if (index === values.length - 1) {
                  return Aux.convertBytes(maxDataAmountValue);
                } else return "";
              },
            },
            grid: {
              display: false,
              drawBorder: false,
            },
            position: "right",
          },
        },
        title: {
          display: false,
          text: "Data Organized In Bars",
          fontSize: 25,
        },
        layout: {
          padding: {
            left: 30,
            right: 30,
          },
        },
        plugins: {
          legend: {
            display: false,
            position: "top",
          },
          tooltip: {
            enabled: true,
            mode: "x",
            intersect: false,
            callbacks: {
              label: (context: any) => {
                var label = context.dataset.label || "";

                if (label) {
                  label += ": ";
                }

                if (context.parsed.y !== null) {
                  label += Aux.convertBytes(context.parsed.y);
                }
                return label;
              },
            },
          },
        },
      },
    };
  }

  const ExampleCustomInput = React.forwardRef<HTMLButtonElement, Props>(({ onClick }, ref: any) => (
    <div className={displaySingleDate ? "data-line-graph-singledate-icon-container-picked" : "data-line-graph-singledate-icon-container"}>
      {displaySingleDate ? (
        <img
          src={iconSingleDayPicked}
          alt="Logo"
          className="data-line-graph-singledate-icon-picked"
          onClick={(e) => {
            setDisplaySingleDate(true);
            onClick(e);
          }}
          ref={ref}
        />
      ) : (
        <img
          src={iconSingleDay}
          alt="Logo"
          className="data-line-graph-singledate-icon"
          onClick={(e) => {
            setDisplaySingleDate(true);
            onClick(e);
          }}
          ref={ref}
        />
      )}
    </div>
  ));

  function handleOnClick() {
    // setDisplaySingleDate(true);
  }

  React.useEffect(() => {}, [dictionary]);

  React.useEffect(() => {
    window.history.pushState(null, "", window.location.pathname);
    window.onpopstate = (e: any) => {
      history.goBack();
    };

    Requests.getCategories()
      .then((response: any) => {
        let listCategories: DataCategory[] = [];
        dataCategoriesListFromServer.current = response.categories;
        response.categories.forEach((element: any) => {
          let tempCat = new DataCategory();
          tempCat.id = element.id;
          tempCat.name = element.name[Storage.getActiveLanguage()];
          tempCat.dataUsed = 0;
          tempCat.dataLimit = 0;
          listCategories.push(tempCat);
        });
        setDataCategoriesList([]);
        getCategoriesData(listCategories, startDate, endDate, true);
      })
      .catch((ex) => {
        Aux.handleErrors(history, ex, dictionary);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function getCategoriesData(categoriesList: any[], startDat: Date, endDat: Date, updateDataCategoryList?: boolean) {
    if (!categoriesList || categoriesList.length === 0) return;

    let listCategories = categoriesList.filter((x: any) => x.selected);
    let list: string[] = listCategories.map((x: any) => x.id);

    if (listCategories.length === 0) {
      listCategories = [...categoriesList];

      /* add Others category */
      let othersCat = new DataCategory();
      othersCat.id = "Others";
      othersCat.name = dictionary.data.categories.others;
      othersCat.dataUsed = 0;
      othersCat.dataLimit = 0;
      listCategories.push(othersCat);
    }

    const categoriesWithDataUsed: string[] = [];
    Requests.getCategoriesUsage(list, startDate, endDate)
      .then((response: any) => {
        let lTotalCategoriesSpend = 0;
        response?.data_usage?.forEach((elementData: any) => {
          listCategories.forEach((elementCat) => {
            if (elementData.category.id === elementCat.id) {
              elementCat.dataUsed = elementData.data_used;
              elementCat.dataLimit = 0;

              if (elementCat.dataUsed > 0) {
                categoriesWithDataUsed.push(elementData.category.id);
              }
            }
          });

          lTotalCategoriesSpend += elementData.data_used;
        });
        listCategories = listCategories.sort((x, y) => y?.dataUsed - x?.dataUsed);

        if (updateDataCategoryList) {
          setDataCategoriesList(categoriesList.filter((c) => categoriesWithDataUsed.indexOf(c.id) >= 0));
        }

        setTotalCategoriesSpend(lTotalCategoriesSpend);
        setDataCategories(listCategories);
      })
      .catch((ex: any) => {
        Aux.handleErrors(history, ex, dictionary);
      });
  }

  function onDataCategoryClick(index: number) {
    const tempArray = [...dataCategoriesList];
    setDataCategoriesList(tempArray);
    onChangeDates(startDate, endDate, singleDate, type);
  }

  function onChangeDates(startDateFromDatePicker: Date, endDateFromDatePicker: Date, singleDateFromDatePicker: Date, graphType: number) {
    let dataArray: any[] = [];
    let dataArrayAverage: any[] = [];
    let startDate = new Date(startDateFromDatePicker);
    let maxDataAmount = 0;
    let sumDataAmount = 0;
    let count = 0;
    let average = 0;
    let list: string[] = dataCategoriesList.filter((x: any) => x.selected === true).map((x: any) => x.id);
    setLoadingGraph(true);
    setEmptyData(false);
    if (graphType === 0) {
      Requests.getCategoriesDailyUsage(list, startDateFromDatePicker, endDateFromDatePicker)
        .then((response: any) => {
          response?.data_usage?.forEach((elementData: any) => {
            let dataAmount = elementData.data_used;
            sumDataAmount += dataAmount;
            count++;
            maxDataAmount = Math.max(dataAmount, maxDataAmount);
            let date = new Date(elementData.day);
            date.setHours(0);
            date.setMinutes(0);
            date.setSeconds(0);
            dataArray.push({ x: date.valueOf(), y: dataAmount });
          });
          startDate = new Date(startDateFromDatePicker);
          average = sumDataAmount / count;
          for (; startDate < endDateFromDatePicker; startDate = Aux.addDays(startDate, 1)) {
            dataArrayAverage.push({ x: startDate.valueOf(), y: average });
          }

          createGraphs(startDateFromDatePicker, endDateFromDatePicker, dataArray, dataArrayAverage, maxDataAmount, sumDataAmount, average, graphType);
          setLoadingGraph(false);
        })
        .catch((ex: any) => {
          Aux.handleErrors(history, ex, dictionary);
          setEmptyData(true);
          setLoadingGraph(false);
        });

      getCategoriesData(dataCategoriesList, startDateFromDatePicker, endDateFromDatePicker);
    } else if (graphType === 1) {
      Requests.getCategoriesHourlyUsage(list, singleDateFromDatePicker)
        .then((response: any) => {
          let hour = 0;
          response?.data_usage?.forEach((elementData: any) => {
            let dataAmount = elementData.data_used;
            sumDataAmount += dataAmount;
            count++;
            maxDataAmount = Math.max(dataAmount, maxDataAmount);
            let date = new Date(singleDateFromDatePicker);
            date.setHours(hour);
            hour++;
            date.setMinutes(0);
            date.setSeconds(0);
            dataArray.push({ x: date.valueOf(), y: dataAmount });
          });
          average = sumDataAmount / count;
          for (let i = 0; i < 24; i++) {
            let tempDate = new Date(singleDateFromDatePicker);
            tempDate.setHours(i);
            tempDate.setMinutes(0);
            tempDate.setSeconds(0);
            dataArrayAverage.push({ x: tempDate.valueOf(), y: average });
          }
          createGraphs(startDateFromDatePicker, endDateFromDatePicker, dataArray, dataArrayAverage, maxDataAmount, sumDataAmount, average, graphType);
          setLoadingGraph(false);
        })
        .catch((ex: any) => {
          Aux.handleErrors(history, ex, dictionary);
          setEmptyData(true);
          setLoadingGraph(false);
        });

      getCategoriesData(dataCategoriesList, singleDateFromDatePicker, singleDateFromDatePicker);
    }
  }

  function createGraphs(
    startDateFromDatePicker: Date,
    endDateFromDatePicker: Date,
    dataArray: any[],
    dataArrayAverage: any[],
    maxDataAmount: number,
    sumDataAmount: number,
    average: number,
    graphType: number
  ) {
    const dataLine = {
      datasets: [
        {
          label: "Data",
          data: dataArray,
          borderColor: "#e60000",
          borderWidth: 2,
          fill: false,
          lineTension: 0,
          pointRadius: 0,
        },
        {
          label: "Average",
          data: dataArrayAverage,
          borderColor: "#007c92",
          borderWidth: 2,
          fill: false,
          lineTension: 0,
          pointRadius: 0,
          borderDash: [5, 5],
        },
      ],
    };

    const optionsLine = makeGraphOptions(graphType, maxDataAmount);

    setTotalGraphSpend(Math.floor(sumDataAmount));
    setAverageGraphSpend(Math.floor(average));
    setBarData(dataLine);
    setBarOptions(optionsLine);
    setStartDate(startDateFromDatePicker);
    setEndDate(endDateFromDatePicker);
  }

  function renderHome() {
    return (
      <div>
        <div>
          <HomeMenu show={showMenu} onCloseClick={setShowMenu}></HomeMenu>
        </div>
        <div>
          <HomeTopMenu showWhite={true} onMenuClick={setShowMenu} title={dictionary?.data?.title}></HomeTopMenu>
          <div className="container">
            <div className="row">
              <DataDatePicker
                startDateText={dictionary?.data?.startDateText}
                endDateText={dictionary?.data?.endDateText}
                onChangeDates={(startDatePicker: Date, endDatePicker: Date) => {
                  setType(0);
                  onChangeDates(startDatePicker, endDatePicker, singleDate, type);
                }}
              />
            </div>
            <div className="row">
              <CategoriesPicker categories={dataCategoriesList} onItemClick={onDataCategoryClick}></CategoriesPicker>
            </div>
            <div className="row">
              <RangueType
                type={type}
                type0Text={dictionary?.data?.globalGraph}
                type1Text={dictionary?.data?.dailyGraph}
                onItemClick={(value: number) => {
                  setType(value);
                  onChangeDates(startDate, endDate, singleDate, value);
                }}
              ></RangueType>
            </div>
            <div className="row">
              {emptyData ? (
                <EmptyData />
              ) : (
                <div className="data-line-graph">
                  <div>
                    {loadingGraph ? (
                      <span className="data-line-graph-dates loading-placeholder">&nbsp;</span>
                    ) : (
                      <div className="data-line-graph-dates">{type === 0 ? format(startDate, "dd MMM") + " - " + format(endDate, "dd MMM") : format(singleDate, "dd MMM")}</div>
                    )}
                    {type === 1 ? (
                      <div className="data-line-graph-singledate">
                        <DatePicker
                          locale={dictionary?.type}
                          minDate={startDate}
                          maxDate={endDate}
                          customInput={<ExampleCustomInput onClick={handleOnClick} />}
                          selected={singleDate}
                          onChange={(date: Date) => {
                            setSingleDate(date);
                            onChangeDates(startDate, endDate, date, type);
                          }}
                          onCalendarClose={() => setDisplaySingleDate(false)}
                          className="data-date-datePicker data-date-datePicker-left"
                          onFocus={(e) => e.target.blur()}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  {loadingGraph ? (
                    <span className="data-line-graph-type loading-placeholder">&nbsp;</span>
                  ) : (
                    <div className="data-line-graph-type">{type === 0 ? dictionary?.data?.globalSpent : format(startDate, "dd MMM") + " - " + format(endDate, "dd MMM")} </div>
                  )}
                  {loadingGraph ? <hr className="data-line-graph-empty" /> : <Line data={barData} options={barOptions.options} />}
                  <div>
                    <div className="data-line-graph-total">
                      <div className={loadingGraph ? "data-line-graph-loading-bullet data-line-graph-total-bullet" : "data-line-graph-total-bullet"}>&#8226;</div>
                      <div className="data-line-graph-td-title">{dictionary?.data?.totalSpend}</div>
                      <div className="data-line-graph-td-spent">{loadingGraph ? "-" : Aux.convertBytes(totalGraphSpend)}</div>
                    </div>
                    <div className="data-line-graph-daily">
                      <div className={loadingGraph ? "data-line-graph-loading-bullet data-line-graph-daily-bullet" : "data-line-graph-daily-bullet"}>&#8226;</div>
                      <div className="data-line-graph-td-title">{dictionary?.data?.averageDailySpend}</div>
                      <div className="data-line-graph-td-spent">{loadingGraph ? "-" : Aux.convertBytes(averageGraphSpend)}</div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="row">
              <CategoriesList items={dataCategories} totalSpent={totalCategoriesSpend} categoriesText={dictionary?.data?.categories.text} source="data"></CategoriesList>
            </div>
          </div>
        </div>
      </div>
    );
  }

  function renderDesktop() {
    return (
      <div className="container">
        <div className="row main-row">
          <div className="support-data-left-div">
            <Menu selected={MenuItems.Data}></Menu>
          </div>
          <div className="support-data-main-div-full">
            <div className="container">
              <div className="container support-data-container">
                <div className="row justify-content-center">
                  <span className="support-data-title">{dictionary?.data?.title}</span>
                </div>
                <div className="row justify-content-center support-data-datePicker">
                  <DataDatePicker
                    startDateText={dictionary?.data?.startDateText}
                    endDateText={dictionary?.data?.endDateText}
                    onChangeDates={(startDatePicker: Date, endDatePicker: Date) => {
                      setType(0);
                      onChangeDates(startDatePicker, endDatePicker, singleDate, type);
                    }}
                  />
                </div>
                <div className="row justify-content-center support-data-statusPicker">
                  <CategoriesPicker categories={dataCategoriesList} onItemClick={onDataCategoryClick}></CategoriesPicker>
                </div>
                <div className="row justify-content-center">
                  <div className="support-data-rangue">
                    <RangueType
                      type={type}
                      type0Text={dictionary?.data?.globalGraph}
                      type1Text={dictionary?.data?.dailyGraph}
                      onItemClick={(value: number) => {
                        setType(value);
                        onChangeDates(startDate, endDate, singleDate, value);
                      }}
                    ></RangueType>
                  </div>
                </div>
                <div className="row justify-content-center">
                  {emptyData ? (
                    <EmptyData />
                  ) : (
                    <div className="data-line-graph">
                      <div>
                        {loadingGraph ? (
                          <span className="data-line-graph-dates loading-placeholder">&nbsp;</span>
                        ) : (
                          <div className="data-line-graph-dates">{type === 0 ? format(startDate, "dd MMM") + " - " + format(endDate, "dd MMM") : format(singleDate, "dd MMM")}</div>
                        )}
                        {type === 1 ? (
                          <div className="data-line-graph-singledate">
                            <DatePicker
                              locale={dictionary?.type}
                              minDate={startDate}
                              maxDate={endDate}
                              customInput={<ExampleCustomInput onClick={handleOnClick} />}
                              selected={singleDate}
                              onChange={(date: Date) => {
                                setSingleDate(date);
                                onChangeDates(startDate, endDate, date, type);
                              }}
                              onCalendarClose={() => setDisplaySingleDate(false)}
                              className="data-date-datePicker data-date-datePicker-left"
                            />
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      {loadingGraph ? (
                        <span className="data-line-graph-type loading-placeholder">&nbsp;</span>
                      ) : (
                        <div className="data-line-graph-type">{type === 0 ? dictionary?.data?.globalSpent : format(startDate, "dd MMM") + " - " + format(endDate, "dd MMM")} </div>
                      )}
                      {loadingGraph ? <hr className="data-line-graph-empty" /> : <Line data={barData} options={barOptions.options} />}
                      <div>
                        <div className="data-line-graph-total">
                          <div className={loadingGraph ? "data-line-graph-loading-bullet data-line-graph-total-bullet" : "data-line-graph-total-bullet"}>&#8226;</div>
                          <div className="data-line-graph-td-title">{dictionary?.data?.totalSpend}</div>
                          <div className="data-line-graph-td-spent">{loadingGraph ? "-" : Aux.convertBytes(totalGraphSpend)}</div>
                        </div>
                        <div className="data-line-graph-daily">
                          <div className={loadingGraph ? "data-line-graph-loading-bullet data-line-graph-daily-bullet" : "data-line-graph-daily-bullet"}>&#8226;</div>
                          <div className="data-line-graph-td-title">{dictionary?.data?.averageDailySpend}</div>
                          <div className="data-line-graph-td-spent">{loadingGraph ? "-" : Aux.convertBytes(averageGraphSpend)}</div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="row justify-content-center">
                  <div className="support-data-categories">
                    <CategoriesList items={dataCategories} totalSpent={totalCategoriesSpend} categoriesText={dictionary?.data?.categories.text} source="data"></CategoriesList>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  function render() {
    return isDesktop ? renderDesktop() : renderHome();
  }

  return <div className="App">{render()}</div>;
};

export default Data;
