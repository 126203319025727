import React from "react";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { Button } from "../../components/button";

import mainLogoRed from "../../assets/logos-vodafone-red.svg";
import menuCloseWhite from "../../assets/icons-close-red.svg";
import menuBack from "../../assets/icons-arrow-arrow-left.svg";
import "./newTicket.scss";
import "./newTicketDataAccess.scss";

import { useHistory } from "react-router-dom";
import Menu, { MenuItems } from "../home/menu";
import Storage from "../../storage";
import { Inputs } from "../../models";
import { TICKET_SUMMARY_MAX_LENGTH } from "../../model/tickets";

export const NewTicketDataAccess: React.FC = () => {
  const dictionary = useSelector((state: any) => state.selectedDictionary);
  const history = useHistory();
  const isDesktop = useMediaQuery({ query: "(min-width: 600px)" });
  const inputEmail = React.useRef<HTMLInputElement>(null);
  const summaryElement = React.useRef<HTMLTextAreaElement>(null);
  const [textAreaCount, setTextAreaCount] = React.useState<number>(Storage.getNewTicketDescription()?.length || 0);
  const [continueButtonDisabled, setContinueButtonDisabled] = React.useState<boolean>(true);

  
  React.useEffect(() => {
    let email = Storage.getNewTicketEmail() as string;
    if (email && email.length > 0 && inputEmail && inputEmail.current) inputEmail.current.value = email;

    let description = Storage.getNewTicketDescription() as string;
    if (description && description.length > 0 && summaryElement && summaryElement.current) summaryElement.current.value = description;
  }, []);

  React.useEffect(() => {
    window.history.pushState(null, "", window.location.pathname);
    window.onpopstate = () => {
      history.goBack();
    };
  }, [history]);

  function onEmailChange(e: any) {
    setContinueButtonDisabled(!Inputs.isValidEmail(inputEmail.current?.value) ||
      summaryElement.current?.value.length === 0);
  }

  function onSummaryChange(e: any) {
    setContinueButtonDisabled(!Inputs.isValidEmail(inputEmail.current?.value) ||
      summaryElement.current?.value.length === 0);
    
    if (e.target.value.length > TICKET_SUMMARY_MAX_LENGTH) {
      e.target.value = e.target.value.slice(0, TICKET_SUMMARY_MAX_LENGTH);
    }
    setTextAreaCount(e.target.value.length);
  }

  function handleCloseClick() {
    history.push("/tickets");
  }

  function handleContinueClick() {
    Storage.setNewTicketEmail(inputEmail?.current?.value as string);
    if (summaryElement?.current) Storage.setNewTicketDescription(summaryElement.current.value);
    history.push("/newticketconfirm/pending");
  }

  function renderMobile() {
    return (
      <div className="container">
        <div className="row">
          <div className="newticket-top-menu-left">
            <img src={mainLogoRed} alt="Logo" className="newticket-top-menu-logo" />
          </div>
          <div className="newticket-top-menu-midle">{dictionary?.tickets?.newTicketType?.title}</div>
          <div className="newticket-top-menu-right">
            <img src={menuCloseWhite} alt="Logo" className="newticket-top-menu-close" onClick={handleCloseClick} />
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="newTicketEmailDataAccess-labelTop newTicketEmail-labelTop">{dictionary?.tickets?.newTicketEmail?.emailTopLabel}</div>
          <input ref={inputEmail}
            className="newTicketEmailDataAccess-email newTicketEmail-email"
            type="email"
            placeholder="example@example.com"
            onChange={onEmailChange} />
          <div className="newTicketEmailDataAccess-labelBottom newTicketEmail-labelBottom">{dictionary?.tickets?.newTicketEmail?.emailBottomLabel}</div>
        </div>
        <div className="row justify-content-center">
          <textarea
            ref={summaryElement}
            placeholder={dictionary?.tickets.personalData.dataAccess.summaryLabel}
            className="newticket-summary"
            onChange={onSummaryChange}
          ></textarea>
          <div className="newticket-summary-count">{textAreaCount}/{TICKET_SUMMARY_MAX_LENGTH}</div>
        </div>
        <div className="row justify-content-center">
          <Button className="button newTicket-continueButton" 
            text={dictionary?.general.buttons.continue}
            disabled={continueButtonDisabled}
            onClick={handleContinueClick}>
          </Button>
        </div>
      </div>
    );
  }

  function renderDesktop() {
    return (
      <div className="container">
        <div className="row main-row">
          <div className="support-data-left-div">
            <Menu selected={MenuItems.Tickets}></Menu>
          </div>
          <div className="support-data-main-div-full">
            <div className="row justify-content-center">
              <img
                src={menuBack}
                alt="Logo"
                className="newticket-top-menu-back"
                onClick={() => { history.goBack(); }}
              />
            </div>
            <div className="row justify-content-center">
              <div className="newticket-text">{dictionary?.tickets.personalData.dataAccess.label}</div>
            </div>
            <div className="row justify-content-center">
              <div className="newticket-subText">
                {dictionary?.tickets.personalData.dataAccess.subText}
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="newTicketEmail-labelTop">{dictionary?.tickets?.newTicketEmail?.emailTopLabel}</div>
                <input
                  ref={inputEmail}
                  className="newTicketEmailDataAccess-email newTicketEmail-email"
                  type="email"
                  placeholder="example@example.com"
                  onChange={onEmailChange}
                />
              <div className="newTicketEmail-labelBottom">{dictionary?.tickets?.newTicketEmail?.emailBottomLabel}</div>
            </div>
            <div className="row justify-content-center">
              <div className="newticket-label-message-top">{dictionary?.tickets?.newTicketPS?.message}</div>
              <textarea
                ref={summaryElement}
                placeholder={dictionary?.tickets.personalData.dataAccess.summaryLabel}
                className="newticket-summary"
                onChange={onSummaryChange}
              ></textarea>
              <div className="newticket-summary-count">{textAreaCount}/{TICKET_SUMMARY_MAX_LENGTH}</div>
            </div>
            <div className="row justify-content-center">
              <Button className="button newTicket-continueButton" 
                text={dictionary?.general.buttons.continue}
                disabled={continueButtonDisabled}
                onClick={handleContinueClick}>
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  function render() {
    return isDesktop ? renderDesktop() : renderMobile();
  }

  return <div className="App">{render()}</div>;
};

export default NewTicketDataAccess;
