import React from "react";
import { useSelector } from "react-redux";
import { format } from "date-fns";

import icAppsWhite from "./../../assets/icons-ticket-red-detail.svg";
import iconsClose from "./../../assets/icons-close.svg";

import "./ticketDetailDesktop.scss";

interface DataCategoryDetailProps {
  ticket: any;
  typeList: any;
  onCloseClick: (value: boolean) => void;
}

export const TicketDetailDesktop: React.FC<DataCategoryDetailProps> = (props: DataCategoryDetailProps) => {
  const dictionary = useSelector((state: any) => state.selectedDictionary);

  React.useEffect(() => {
    window.onpopstate = handleOnClose;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleOnClose() {
    props.onCloseClick(false);
  }

  function translateStatus(status: string) {
    let result = status;
    if (dictionary && dictionary.tickets && dictionary.tickets.statusList) {
      let listFromText = JSON.parse(dictionary.tickets.statusList);
      listFromText.forEach((element: { id: string; name: string }) => {
        if (element.id === status) result = element.name;
      });
    }
    return result;
  }

  function renderParamValue(param: string, value: string, default_value: string) {
    const val = !value || value.length === 0 ? default_value : value;
    if (!val || val.length === 0) return;
    return (
      <div>
        <div className="row support-ticket-detail-param">{param}</div>
        <div className="row support-ticket-detail-value">{!value || value.length === 0 ? default_value : value}</div>
      </div>
    );
  }

  function render() {
    if (!props.ticket) return null;

    let type = props.ticket.type;
    props.typeList?.forEach((element: any) => {
      if (element.type === props.ticket.type) type = element?.name[dictionary?.type];
    });

    return (
      <div className="container">
        <div className="row">
          <div className="support-ticket-detail-close-container" onClick={handleOnClose}>
            <img src={iconsClose} alt="Logo" className="support-ticket-detail-close" />
          </div>
          <img src={icAppsWhite} alt="Logo" className="support-ticket-detail-logo" />
        </div>
        <div className="row support-ticket-detail-title">{dictionary?.tickets?.detailTitle}</div>
        {renderParamValue(dictionary?.tickets?.detailId, Number(props.ticket.ticketId).toString(), dictionary?.tickets?.detailIdDefault)}
        {renderParamValue(dictionary?.tickets?.detailType, type, dictionary?.tickets?.detailTypeDefault)}
        {renderParamValue(dictionary?.tickets?.detailSubject, props.ticket.summary, dictionary?.tickets?.detailSubjectDefault)}
        {renderParamValue(dictionary?.tickets?.detailDescription, props.ticket.requestDescription, dictionary?.tickets?.detailDescriptionDefault)}
        {renderParamValue(dictionary?.tickets?.detailChannel, props.ticket.channel, dictionary?.tickets?.detailChannelDefault)}
        {renderParamValue(dictionary?.tickets?.detailResolutionMethod, props.ticket.resolutionMethod, dictionary?.tickets?.detailResolutionMethodDefault)}
        {renderParamValue(dictionary?.tickets?.detailResolutionSummary, props.ticket.resolutionSummary, dictionary?.tickets?.detailResolutionSummaryDefault)}
        {renderParamValue(dictionary?.tickets?.detailCreateDate, format(new Date(props.ticket.createDate), "yyyy-MM-dd, HH:mm"), dictionary?.tickets?.detailCreateDateDefault)}
        {renderParamValue(dictionary?.tickets?.detailLastDate, format(new Date(props.ticket.modifiedDate), "yyyy-MM-dd, HH:mm"), dictionary?.tickets?.detailLastDateDefault)}
        {renderParamValue(dictionary?.tickets?.detailStatus, translateStatus(props.ticket.status), translateStatus(dictionary?.tickets.detailStatusDefault))}
      </div>
    );
  }
  return render();
};

export default TicketDetailDesktop;
