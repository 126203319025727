import React from "react";
import mainLogoRed from "../../assets/logos-vodafone-red.svg";
import menuCloseWhite from "../../assets/icons-close-red.svg";
import { useSelector } from "react-redux";

import "./ticketDetail.scss";
import { format } from "date-fns";

const Constructor = (callBack: () => void) => {
  const hasBeenCalled = React.useRef<boolean>(false);
  if (hasBeenCalled.current) return;
  callBack();
  hasBeenCalled.current = true;
};

interface TicketDetailProps {
  ticket: any;
  typeList: any;
  show: boolean;
  onCloseClick: (data: any) => void;
}

export const TicketDetail: React.FC<TicketDetailProps> = (props: TicketDetailProps) => {
  const dictionary = useSelector((state: any) => state.selectedDictionary);

  Constructor(() => {});

  React.useEffect(() => {
    window.onpopstate = handleCloseClick;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleCloseClick() {
    props.onCloseClick(false);
  }

  function translateStatus(status: string) {
    let result = status;
    if (dictionary && dictionary.tickets && dictionary.tickets.statusList) {
      let listFromText = JSON.parse(dictionary.tickets.statusList);
      listFromText.forEach((element: { id: string; name: string }) => {
        if (element.id === status) result = element.name;
      });
    }
    return result;
  }

  function renderParamValue(param: string, value: string, default_value: string) {
    const val = !value || value.length === 0 ? default_value : value;
    if (!val || val.length === 0) return;
    return (
      <div>
        <div className="row ticket-detail-name">{param}</div>
        <div className="row ticket-detail-value">{!value || value.length === 0 ? default_value : value}</div>
      </div>
    );
  }

  function render() {
    if (!props.show) return null;
    else {
        
      let type = props.ticket.type;
      props.typeList?.forEach((element: any) => {
        if (element.type === props.ticket.type) type = element?.name[dictionary?.type];
      });

      return (
        <div className="container-fluid home-menu">
          <div className="row">
            <div className="col-3 home-top-menu-left">
              <img src={mainLogoRed} alt="Logo" className="home-menu-logo" />
            </div>
            <div className="col-6 home-top-menu-midle">{props.ticket.description}</div>
            <div className="col-3 home-top-menu-right">
              <img src={menuCloseWhite} alt="Logo" className="home-menu-close" onClick={handleCloseClick} />
            </div>
          </div>
          <div className="row ticket-detail-container">
            {renderParamValue(dictionary?.tickets?.detailId, Number(props.ticket.ticketId).toString(), dictionary?.tickets?.detailIdDefault)}
            {renderParamValue(dictionary?.tickets?.detailType, type, dictionary?.tickets?.detailTypeDefault)}
            {renderParamValue(dictionary?.tickets?.detailSubject, props.ticket.summary, dictionary?.tickets?.detailSubjectDefault)}
            {renderParamValue(dictionary?.tickets?.detailDescription, props.ticket.requestDescription, dictionary?.tickets?.detailDescriptionDefault)}
            {renderParamValue(dictionary?.tickets?.detailChannel, props.ticket.channel, dictionary?.tickets?.detailChannelDefault)}
            {renderParamValue(dictionary?.tickets?.detailResolutionMethod, props.ticket.resolutionMethod, dictionary?.tickets?.detailResolutionMethodDefault)}
            {renderParamValue(dictionary?.tickets?.detailResolutionSummary, props.ticket.resolutionSummary, dictionary?.tickets?.detailResolutionSummaryDefault)}
            {renderParamValue(dictionary?.tickets?.detailCreateDate, format(new Date(props.ticket.createDate), "yyyy-MM-dd, HH:mm"), dictionary?.tickets?.detailCreateDateDefault)}
            {renderParamValue(dictionary?.tickets?.detailLastDate, format(new Date(props.ticket.modifiedDate), "yyyy-MM-dd, HH:mm"), dictionary?.tickets?.detailLastDateDefault)}
            {renderParamValue(dictionary?.tickets?.detailStatus, translateStatus(props.ticket.status), translateStatus(dictionary?.tickets?.detailStatusDefault))}
          </div>
        </div>
      );
    }
  }

  return render();
};

export default TicketDetail;
