import React from "react";
import mainLogoRed from "../../assets/logos-vodafone-red.svg";
import menuCloseWhite from "../../assets/ic-close-white.svg";
import menuLogout from "../../assets/ic-log-out.svg";
import Storage from "../../storage";
import { useHistory } from "react-router-dom";

import { selectedDictionaryReducer } from "../../store/actions";
import { useDispatch, useSelector } from "react-redux";

import { ConfigUtils, Feature } from "../../models";

import "./homeMenu.scss";

interface HomeMenuProps {
  show: boolean;
  onCloseClick: (data: any) => void;
}

export const HomeMenu: React.FC<HomeMenuProps> = ({ show, onCloseClick }) => {
  const [showLogout, setShowLogout] = React.useState<boolean>(false);
  const dictionary = useSelector((state: any) => state.selectedDictionary);
  const languageSelect = React.useRef<HTMLSelectElement>(null);
  const history = useHistory();
  const dispatch = useDispatch();

  React.useLayoutEffect(() => {
    if (languageSelect.current) languageSelect.current.value = Storage.getActiveLanguage() as string;
  }, [show]);

  function handleCloseClick() {
    onCloseClick(false);
  }

  function handleHomeClick() {
    onCloseClick(false);
    history.push("/home");
  }

  function handleDataClick() {
    onCloseClick(false);
    history.push("/data");
  }

  function handleTicketsClick() {
    onCloseClick(false);
    history.push("/tickets");
  }

  function handleLogoutClick() {
    setShowLogout(true);
  }

  function handleLogoutFinalClick() {
    Storage.setSession(null);
    history.push("/loginphonenumber");
  }

  function handleCloseLogoutClick() {
    setShowLogout(false);
  }

  function onChangeLanguage(event: any) {
    Storage.setActiveLanguage(event.target.value);
    dispatch(selectedDictionaryReducer(event.target.value));
  }

  function renderLogout() {
    return (
      <div className="row home-menu-logout-background">
        <div className="row home-logout-container">
          <div className="row justify-content-center home-logout-title">{dictionary?.homeMenu?.logout}</div>
          <div className="row justify-content-center home-logout-text">{dictionary?.homeMenu?.logoutText}</div>
          <button className="row justify-content-center home-logout-button-logout" onClick={handleLogoutFinalClick}>
            {dictionary?.homeMenu?.logout}
          </button>
          <button className="row justify-content-center home-logout-button-cancel" onClick={handleCloseLogoutClick}>
            {dictionary?.homeMenu?.logoutCancel}
          </button>
        </div>
      </div>
    );
  }

  function render() {
    if (!show) return null;
    else
      return (
        <div className="container-fluid home-menu">
          {showLogout ? renderLogout() : null}
          <div className="row">
            <div className="home-menu-left">
              <img src={mainLogoRed} alt="Logo" className="home-menu-logo" />
            </div>
            <div className="home-menu-right">
              <img src={menuCloseWhite} alt="Logo" className="home-menu-close" onClick={handleCloseClick} />
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="home-menu-home" onClick={handleHomeClick}>
              {dictionary?.homeMenu?.home}
            </div>
          </div>
          {
            ConfigUtils.isFeatureEnabled(Feature.DataStatement) &&
              <div className="row justify-content-center">
                <div className="home-menu-mobile" onClick={handleDataClick}>
                  {dictionary?.homeMenu?.mobileData}
                </div>
              </div>
          }
          {
            ConfigUtils.isFeatureEnabled(Feature.Tickets) &&
              <div className="row justify-content-center">
                <div className="home-menu-tickets" onClick={handleTicketsClick}>
                  {dictionary?.homeMenu?.tickets}
                </div>
              </div>
          }
          <div className="row justify-content-center" onClick={handleLogoutClick}>
            <div className="home-menu-logout">
              <div className="home-menu-logout-items">
                <img src={menuLogout} alt="Logo" />
              </div>
              <div className="home-menu-logout-items">&nbsp;&nbsp;{dictionary?.homeMenu?.logout}</div>
            </div>
          </div>
          <div className="row justify-content-center">
            <select ref={languageSelect} className="home-menu-language" onChange={onChangeLanguage}>
              <option value="en">English</option>
              <option value="pt">Português</option>
            </select>
          </div>
          <div className="row justify-content-center">
            <div className="col-12 home-menu-pp">
              <a href={Config.PrivacyPolicyLink} target="_blank" rel="noreferrer">{dictionary?.general?.privacyPolicy.label}</a>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-12 home-menu-tc">
              <a href={Config.TermsAndConditionsLink} target="_blank" rel="noreferrer">{dictionary?.general?.termsAndConditions.label}</a>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-12 home-menu-hf">
              <a href={Config.FAQLink} target="_blank" rel="noreferrer">{dictionary?.general?.helpFAQs}</a>
            </div>
          </div>
        </div>
      );
  }

  return render();
};

export default HomeMenu;
