import { Aux, OTPResponse, OTPValidateResponse } from "./models";
import CryptoJS from "crypto-js";

const DEFAULT_LANGUAGE = "pt";

export class Storage {

  private static initialized: boolean = false;
  private static otp: OTPResponse | null = null;
  private static session: OTPValidateResponse | null = null;
  private static otpLogin: string | null = null;
  private static newTicketRequestId: string | null = null;
  private static newTicketType: string | null = null;
  private static newTicketItem: string | null = null;
  private static newTicketTypeObject: any | null = null;
  private static newTicketSubject: string | null = null;
  private static newTicketEmail: string | null = null;
  private static newTicketRange: string | null = null;
  private static newTicketDescription: string | null = null;
  private static SECRET_KEY: string = "$qHjDzW@2cSy2Q";

  public static _initialize() {
    if (!Storage.initialized) {
      Storage.initialized = true;
    }
  }

  public static getActiveLanguage(): string {
    return localStorage.getItem("activeLanguage") || DEFAULT_LANGUAGE;
  }

  public static setActiveLanguage(language: string) {
    localStorage.setItem("activeLanguage", language);
  }

  public static getOTP(): OTPResponse | null {
    return Storage.otp;
  }

  public static setOTP(otp: OTPResponse | null) {
    Storage.otp = otp;
  }

  public static getSession(): OTPValidateResponse | null {
    return Storage.session;
  }

  public static setSession(otp: OTPValidateResponse | null) {
    Storage.session = otp;
    //Store AccessToken
    if (otp !== null)
      Aux.setCookie("accessToken", otp.accessToken as string, 0.05);
    else
      Aux.eraseCookie("accessToken",);
  }

  public static getPhoneNumberLogin(): string | null {
    let phoneLocal = localStorage.getItem("phoneNumberLogin");
    if (phoneLocal) {
      let data = CryptoJS.AES.decrypt(phoneLocal, Storage.SECRET_KEY);
      return data.toString(CryptoJS.enc.Utf8);
    }
    return "";
  }

  public static setPhoneNumberLogin(phoneNumberLogin: string) {
    let data = CryptoJS.AES.encrypt(phoneNumberLogin, Storage.SECRET_KEY);
    localStorage.setItem("phoneNumberLogin", data.toString());
  }

  public static setOtpLogin(otpLogin: string) {
    Storage.otpLogin = otpLogin;
  }

  public static getOtpLogin(): string | null {
    return Storage.otpLogin;
  }

  public static setNewTicketRequestId(value: string) {
    Storage.newTicketRequestId = value;
  }
  public static getNewTicketRequestId(): string | null {
    return Storage.newTicketRequestId;
  }

  public static setNewTicketType(value: string) {
    Storage.newTicketType = value;
  }
  public static getNewTicketType(): string | null {
    return Storage.newTicketType;
  }

  public static setNewTicketItem(value: string) {
    Storage.newTicketItem = value;
  }
  public static getNewTicketItem(): string | null {
    return Storage.newTicketItem;
  }

  public static setNewTicketTypeObject(value: any) {
    Storage.newTicketTypeObject = value;
  }
  public static getNewTicketTypeObject(): any | null {
    return Storage.newTicketTypeObject;
  }

  public static setNewTicketSubject(value: string) {
    Storage.newTicketSubject = value;
  }
  public static getNewTicketSubject(): string | null {
    return Storage.newTicketSubject;
  }

  public static setNewTicketEmail(value: string) {
    Storage.newTicketEmail = value;
  }
  public static getNewTicketEmail(): string | null {
    return Storage.newTicketEmail;
  }

  public static setNewTicketRange(value: string) {
    Storage.newTicketRange = value;
  }
  public static getNewTicketRange(): string | null {
    return Storage.newTicketRange;
  }

  public static setNewTicketDescription(value: string) {
    Storage.newTicketDescription = value;
  }
  public static getNewTicketDescription(): string | null {
    return Storage.newTicketDescription;
  }

  public static setPhoneNumberCatpcha(phoneNumber: string, value: string) {
    localStorage.setItem("Captcha_" + phoneNumber, value);
  }
  public static getPhoneNumberCatpcha(phoneNumber: string): string | null {
    return localStorage.getItem("Captcha_" + phoneNumber);
  }

}
Storage._initialize();

export default Storage;
