import React from "react";
import { Aux, DataCategory } from "../../models";
import ProgressBar from "@ramonak/react-progress-bar";
import "./categoriesList.scss";

interface CategoriesListProps {
  items: DataCategory[];
  totalSpent: number;
  categoriesText: string;
  source: string;
}

export const CategoriesList: React.FC<CategoriesListProps> = ({ items, totalSpent, categoriesText, source }) => {
  function renderItems() {
    const filteredList = items.filter((x: DataCategory) => x.dataUsed !== 0);
    const listItems = filteredList.map((item: DataCategory) => (
      <div key={item.id} className="categories-list-item">
        <div className="categories-list-item-name-limit">
          <div className="categories-list-item-name">{item ? item.name : ""}</div>
          <div className="categories-list-item-limit">{Aux.convertBytes(item.dataUsed as number)}</div>
        </div>
        <div className="categories-list-item-line">
          <ProgressBar
            completed={item?.dataUsed === 0 || isNaN(item?.dataUsed as number) ? 0 : ((item?.dataUsed ?? 0) * 100) / totalSpent}
            bgColor="#e60000"
            baseBgColor="#f4f4f4"
            height="4px"
            isLabelVisible={false}
          />
        </div>
      </div>
    ));
    return listItems;
  }

  function render() {
    let titleCSS = "";
    if (source === "data") titleCSS = "categories-list-title-data";
    if (source === "home") titleCSS = "categories-list-title-home";

    if (items.filter((x: DataCategory) => x.dataUsed !== 0).length === 0) return null;

    return (
      <div className="categories-list-container">
        <div className={titleCSS}>{categoriesText}</div>
        <div>{renderItems()}</div>
      </div>
    );
  }

  return render();
};

export default CategoriesList;
