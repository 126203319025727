import React from "react";

import { useSelector } from "react-redux";
import emptyBoxIcon from "../assets/emptybox.svg";

import "./emptyData.scss";

interface EmptyDataProps {
  message?: string;
}

export const EmptyData: React.FC<EmptyDataProps> = (props: EmptyDataProps) => {
  const dictionary = useSelector((state: any) => state.selectedDictionary);

  function render() {
      return (
        <div className="empty-data">
          <img src={emptyBoxIcon} className="icon-empty-box" alt="empty box" />
          <div>
            <span className="empty-data-label">{props.message || dictionary.general.noData}</span>
          </div>
        </div>);
  }

  return render();
};


export default EmptyData; 
