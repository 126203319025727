import React from "react";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";

import mainLogoRed from "../../assets/logos-vodafone-red.svg";
import menuCloseWhite from "../../assets/icons-close-red.svg";
import menuBack from "../../assets/icons-arrow-arrow-left.svg";
import iconsRightArrow from "../../assets/icons-chevron-right-blue.svg";

import "./newTicket.scss";

import { useHistory } from "react-router-dom";
import Storage from "../../storage";
import Menu, { MenuItems } from "../home/menu";
import { PersonalDataItem, entryPage } from "../../model/tickets";

export const NewTicketSubject: React.FC = () => {
  const dictionary = useSelector((state: any) => state.selectedDictionary);
  const confirmButtonTC = React.useRef<HTMLButtonElement>(null);
  const isDesktop = useMediaQuery({ query: "(min-width: 600px)" });
  const history = useHistory();

  React.useEffect(() => {
    if (confirmButtonTC.current) confirmButtonTC.current.disabled = true;
    window.history.pushState(null, "", window.location.pathname);
    window.onpopstate = () => {
      history.goBack();
    };
  }, [history]);

  function handleCloseClick() {
    history.push("/tickets");
  }

  function handlePersonalDataItemClick(item: PersonalDataItem) {
    Storage.setNewTicketItem(item);
    history.push(entryPage(item));
  }

  function renderMobile() {
    return (
      <div className="container">
        <div className="row">
          <div className="newticket-top-menu-left">
            <img src={mainLogoRed} alt="Logo" className="newticket-top-menu-logo" />
          </div>
          <div className="newticket-top-menu-midle">{dictionary?.tickets?.newTicketType?.title}</div>
          <div className="newticket-top-menu-right">
            <img src={menuCloseWhite} alt="Logo" className="newticket-top-menu-close" onClick={handleCloseClick} />
          </div>
        </div>
        <div className="row justify-content-center newticket-text">{dictionary?.tickets?.newTicketSubject?.text}</div>
        <div className="row justify-content-center newticket-subText">{dictionary?.tickets?.newTicketSubject?.subText}</div>
        <div className="row justify-content-center newticket-type-container">
          {Object.values(PersonalDataItem).map((item: PersonalDataItem) => (
            <div key={item} className="row justify-content-center">
              <button className="newticket-type" onClick={() => handlePersonalDataItemClick(item)}>
                {dictionary?.tickets.personalData[item].label}
              </button>
            </div>
          ))}
        </div>
      </div>
    );
  }

  function renderDesktop() {
    return (
      <div className="container">
        <div className="row main-row">
          <div className="support-data-left-div">
            <Menu selected={MenuItems.Tickets}></Menu>
          </div>
          <div className="support-data-main-div-full">
            <div className="container">
              <div className="row justify-content-center">
                <img
                  src={menuBack}
                  alt="Logo"
                  className="newticket-top-menu-back"
                  onClick={() => {
                    history.goBack();
                  }}
                />
              </div>
              <div className="row justify-content-center">
                <div className="newticket-text">{dictionary?.tickets?.newTicketSubject?.text}</div>
              </div>
              <div className="row justify-content-center">
                <div className="newticket-subText">{dictionary?.tickets?.newTicketSubject?.subText}</div>
              </div>
              <div className="row justify-content-center newticket-type-container">
                {Object.values(PersonalDataItem).map((item: PersonalDataItem) => (
                  <div key={item} className="row justify-content-center ">
                    <div className="content-card" onClick={() => handlePersonalDataItemClick(item)}>
                      <div className="content-card-item-label">{dictionary?.tickets.personalData[item].label}</div>
                      <div className="content-card-item-action" onClick={() => handlePersonalDataItemClick(item)}>
                        <img src={iconsRightArrow} alt="Logo" />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  function render() {
    return isDesktop ? renderDesktop() : renderMobile();
  }

  return <div className="App">{render()}</div>;
};

export default NewTicketSubject;
