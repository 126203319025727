import React from "react";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import Select from "react-select";

import mainLogoRed from "../../assets/logos-vodafone-red.svg";
import menuCloseWhite from "../../assets/icons-close-red.svg";
import menuBack from "../../assets/icons-arrow-arrow-left.svg";
import "./newTicket.scss";
import "./newTicketRange.scss";

import { useHistory } from "react-router-dom";
import Storage from "../../storage";
import Menu, { MenuItems } from "../home/menu";
import { Aux } from "../../models";

export const NewTicketRange: React.FC = () => {
  const dictionary = useSelector((state: any) => state.selectedDictionary);
  const continueButton = React.useRef<HTMLButtonElement>(null);
  const selectedIndex = React.useRef<number>(getSelectedIndex());
  const [selectedValue, setSelectedValue] = React.useState<string>(getSelectedValue());
  const isDesktop = useMediaQuery({ query: "(min-width: 600px)" });
  const history = useHistory();

  function getSelectedIndex(): number {
    let storedSubject = Storage.getNewTicketRange() as string;
    let subjectsArray = getTimeOptions();
    let foundIndex = subjectsArray.findIndex((x) => x.value === storedSubject);
    if (foundIndex >= 0) return foundIndex;
    else return 0;
  }

  function getSelectedValue(): string {
    let storedSubject = Storage.getNewTicketRange() as string;
    if (storedSubject && storedSubject.length > 0) return storedSubject;
    else return getTimeOptions()[0].value;
  }

  function getTimeOptions() {
    let list: { value: string; label: string }[] = [];
    if (dictionary && dictionary.tickets && dictionary.tickets.newTicketRange?.rangueValues) {
      let listFromText = JSON.parse(dictionary.tickets.newTicketRange?.rangueValues);
      listFromText?.forEach((element: { value: string; label: string }) => {
        list.push({ value: element.value, label: element.label });
      });
    }
    return list;
  }

  React.useEffect(() => {
    window.history.pushState(null, "", window.location.pathname);
    window.onpopstate = (e: any) => {
      history.goBack();
    };
  }, [history]);

  function handleCloseClick() {
    history.push("/tickets");
  }

  function handleContinueClick() {
    Storage.setNewTicketRange(selectedValue);
    history.push("/newticketemail");
  }

  function getTitle() {
    const subject = Storage.getNewTicketSubject() as string;
    const title = subject ? dictionary?.tickets?.newTicketSubject[Aux.camelize(subject)] : undefined;
    return title ? title : dictionary?.tickets?.newTicketEmail?.text;
  }

  function renderMobile() {
    return (
      <div className="container">
        <div className="row">
          <div className="newticket-top-menu-left">
            <img src={mainLogoRed} alt="Logo" className="newticket-top-menu-logo" />
          </div>
          <div className="newticket-top-menu-midle">{dictionary?.tickets?.newTicketType?.title}</div>
          <div className="newticket-top-menu-right">
            <img src={menuCloseWhite} alt="Logo" className="newticket-top-menu-close" onClick={handleCloseClick} />
          </div>
        </div>
        <div className="row justify-content-center newticket-text">{dictionary?.tickets?.newTicketRange?.text}</div>
        <div className="row justify-content-center">
          <div className="newticket-subText">{dictionary?.tickets?.newTicketRange?.subText}</div>
        </div>
        <div className="row justify-content-center">
          <div className="newTicketRange-labelTop">{dictionary?.tickets?.newTicketRange?.rangeTopLabel}</div>
          <Select
            isSearchable={false}
            defaultValue={getTimeOptions()[selectedIndex.current]}
            className="newTicketRange-dropdown"
            classNamePrefix="select"
            name="selectTime"
            options={getTimeOptions()}
            onChange={(e: any) => setSelectedValue(e.value)}
            components={{
              IndicatorSeparator: () => null,
            }}
            theme={(theme) => ({
              ...theme,
              borderRadius: 0,
              colors: {
                ...theme.colors,
                primary25: "white",
                primary: "white",
              },
            })}
            styles={{
              option: (provided, { data, isDisabled, isFocused, isSelected }) => ({
                ...provided,
                color: isSelected ? "#e60000" : "#222222",
              }),
              control: (base) => ({
                ...base,
                border: 0,
                // This line disable the blue border
                boxShadow: "none",
              }),
              dropdownIndicator: (base: any, state: any) => ({
                ...base,
                color: "#222222",
                transition: "all .2s ease",
                transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : null,
              }),
            }}
          />
        </div>
        <div className="row justify-content-center">
          <button ref={continueButton} className="button newTicket-continueButton" onClick={handleContinueClick}>
            {dictionary?.general?.buttons?.continue}
          </button>
        </div>
      </div>
    );
  }

  function renderDesktop() {
    return (
      <div className="container">
        <div className="row main-row">
          <div className="support-data-left-div">
            <Menu selected={MenuItems.Tickets}></Menu>
          </div>
          <div className="support-data-main-div-full">
            <div className="row justify-content-center">
              <img
                src={menuBack}
                alt="Logo"
                className="newticket-top-menu-back"
                onClick={() => {
                  history.goBack();
                }}
              />
            </div>
            <div className="row justify-content-center">
              <div className="newticket-text">{getTitle()}</div>
            </div>
            <div className="row justify-content-center">
              <div className="newticket-subText">{dictionary?.tickets?.newTicketRange?.subText}</div>
            </div>
            <div className="row justify-content-center">
              <div className="newTicketRange-labelTop">{dictionary?.tickets?.newTicketRange?.rangeTopLabel}</div>
              <Select
                isSearchable={false}
                defaultValue={getTimeOptions()[selectedIndex.current]}
                className="newTicketRange-dropdown"
                classNamePrefix="select"
                name="selectTime"
                options={getTimeOptions()}
                onChange={(e: any) => setSelectedValue(e.value)}
                components={{
                  IndicatorSeparator: () => null,
                }}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 0,
                  colors: {
                    ...theme.colors,
                    primary25: "white",
                    primary: "white",
                  },
                })}
                styles={{
                  option: (provided, { data, isDisabled, isFocused, isSelected }) => ({
                    ...provided,
                    color: isSelected ? "#e60000" : "#222222",
                  }),
                  control: (base) => ({
                    ...base,
                    border: 0,
                    // This line disable the blue border
                    boxShadow: "none",
                  }),
                  dropdownIndicator: (base: any, state: any) => ({
                    ...base,
                    color: "#222222",
                    transition: "all .2s ease",
                    transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : null,
                  }),
                }}
              />
            </div>
            <div className="row justify-content-center">
              <button ref={continueButton} className="button newTicket-continueButton" onClick={handleContinueClick}>
                {dictionary?.general?.buttons?.continue}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  function render() {
    return isDesktop ? renderDesktop() : renderMobile();
  }

  return <div className="App">{render()}</div>;
};

export default NewTicketRange;
