import React from "react";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useHistory } from "react-router-dom";
import Storage from "../../storage";
import Menu, { MenuItems } from "../home/menu";
import TicketTypesList from "./ticketTypesList";

import mainLogoRed from "../../assets/logos-vodafone-red.svg";
import menuCloseWhite from "../../assets/icons-close-red.svg";
import menuBack from "../../assets/icons-arrow-arrow-left.svg";
import iconUser from "../../assets/icons-user.svg";
import icAppsWhite from "../../assets/ic-apps-white.svg";

import "./newTicket.scss";

const TICKET_TYPE_PERSONAL_DATA = "Personal Data";
const TICKET_TYPE_PRODUCTS_AND_SERVICES = "Products and Services";

export const NewTicketType: React.FC = () => {
  const dictionary = useSelector((state: any) => state.selectedDictionary);
  const confirmButtonTC = React.useRef<HTMLButtonElement>(null);
  const isDesktop = useMediaQuery({ query: "(min-width: 600px)" });
  const history = useHistory();

  React.useEffect(() => {
    if (confirmButtonTC.current) confirmButtonTC.current.disabled = true;
    window.history.pushState(null, "", window.location.pathname);
    window.onpopstate = () => {
      history.goBack();
    };
  }, [history]);

  function handleCloseClick() {
    history.goBack();
  }

  function getTicketTypeIcon(item: any) {
    switch (item.type) {
      case TICKET_TYPE_PERSONAL_DATA:
        return iconUser;
      case TICKET_TYPE_PRODUCTS_AND_SERVICES:
        // return iconStreaming;
        return icAppsWhite;
      default:
        return icAppsWhite;
    }
  }

  function handleClick(item: any) {
    Storage.setNewTicketType(item.type);
    Storage.setNewTicketTypeObject(item);
    Storage.setNewTicketRequestId("");
    Storage.setNewTicketEmail("");
    Storage.setNewTicketSubject("");
    Storage.setNewTicketRange("");
    Storage.setNewTicketDescription("");

    switch (item.type) {
      case TICKET_TYPE_PERSONAL_DATA:
        history.push("/newtickettc");
        break;
      case TICKET_TYPE_PRODUCTS_AND_SERVICES:
        history.push("/newticketdefault");
        break;
      default:
        history.push("/newticketdefault");
        break;
    }
  }

  function renderMobile() {
    return (
      <div className="container">
        <div className="row">
          <div className="newticket-top-menu-left">
            <img src={mainLogoRed} alt="Logo" className="newticket-top-menu-logo" />
          </div>
          <div className="newticket-top-menu-midle">{dictionary?.tickets?.newTicketType?.title}</div>
          <div className="newticket-top-menu-right">
            <img src={menuCloseWhite} alt="Logo" className="newticket-top-menu-close" onClick={handleCloseClick} />
          </div>
        </div>
        <div className="row justify-content-center newticket-text">{dictionary?.tickets?.newTicketType?.text}</div>
        <div className="row justify-content-center">
          <div className="newticket-subText">{dictionary?.tickets?.newTicketType?.subText}</div>
        </div>
        <div className="row justify-content-center">
          <div className="newticket-type-container">
            <TicketTypesList onItemClick={handleClick} getIcon={getTicketTypeIcon} />
          </div>
        </div>
      </div>
    );
  }

  function renderDesktop() {
    return (
      <div className="container">
        <div className="row main-row">
          <div className="support-data-left-div">
            <Menu selected={MenuItems.Tickets}></Menu>
          </div>
          <div className="support-data-main-div-full">
            <div className="container">
              <div className="row justify-content-center">
                <img
                  src={menuBack}
                  alt="Logo"
                  className="newticket-top-menu-back"
                  onClick={() => {
                    history.goBack();
                  }}
                />
              </div>
              <div className="row justify-content-center">
                <div className="newticket-text">{dictionary?.tickets?.newTicketType?.text}</div>
              </div>
              <div className="row justify-content-center">
                <div className="newticket-subText">{dictionary?.tickets?.newTicketType?.subText}</div>
              </div>
              <div className="row justify-content-center">
                <div className="newticket-type-container">
                  <TicketTypesList onItemClick={handleClick} getIcon={getTicketTypeIcon} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  function render() {
    return isDesktop ? renderDesktop() : renderMobile();
  }

  return <div className="App">{render()}</div>;
};

export default NewTicketType;
