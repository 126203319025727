import { dictionary } from "../assets/texts.js";

export const DEFAULT_TICKET_ITEM = "Other";

export const TICKET_SUBJECT_MAX_LENGTH = 255;
export const TICKET_SUMMARY_MAX_LENGTH = 1200;

export enum TicketType {
    PersonalData = "Personal Data",
    ProductsAndServices = "Products and Services",
}

export enum PersonalDataItem {
    RegistrationProof = "registrationProof",
    CallsStatement = "callsStatement",
    DataAccess = "dataAccess"
}

export function itemValue(item: PersonalDataItem) {
    return dictionary.en.tickets.personalData[item].label;
}

export function entryPage(item: PersonalDataItem) {
    switch (item) {
        case PersonalDataItem.CallsStatement:
            return "/newticketrange";
        case PersonalDataItem.DataAccess:
            return "/newticketdataaccess";
        case PersonalDataItem.RegistrationProof:
        default:
            return "/newticketemail";
    }
}
