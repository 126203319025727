import React from "react";
import mainLogo6464 from "../../assets/logo6464.png";
import { dictionary } from "../../assets/texts.js";
import { useHistory, useParams } from "react-router-dom";
import useCountDown from "react-countdown-hook";
import { Requests } from "../../requests";
import { Aux, OTPResponse, OTPValidateResponse } from "../../models";
import { loadReCaptcha, ReCaptcha } from "react-recaptcha-v3";
import Storage from "../../storage";

import "./loginBlocked.scss";

import { LoginFooter } from "./loginFooter";
import { Button } from "../../components/button";

const TYPE_PHONE_NUMBER = "phonenumber";
const TYPE_OTP = "otp";

const REASON_MSISDN_NOT_ALLOWED = "MSISDN_NOT_ALLOWED";
const REASON_INVALID_SIM_SWAP = "INVALID_SIM_SWAP";
// const REASON_NOT_FOUND = "NOT_FOUND";
// const REASON_DISABLE = "DISABLE";
const REASON_NEED_RECAPTCHA = "NEED_RECAPTCHA";
const REASON_INVALID = "INVALID";

const Constructor = (callBack: () => void) => {
  const hasBeenCalled = React.useRef<boolean>(false);
  if (hasBeenCalled.current) return;
  callBack();
  hasBeenCalled.current = true;
};

function makeRequests(token: string, phoneNumberLogin: string, type: string, history: any, activeDictionary: any, setError: (error: boolean) => void) {
  setError(false);

  let needReCaptcha = Storage.getPhoneNumberCatpcha(phoneNumberLogin as string);
  if (needReCaptcha === "true" && token.length === 0) {
    loadReCaptcha(Config.ReCaptchaSiteKey);
  } else {
    if (type === TYPE_PHONE_NUMBER) {
      Requests.getOTP(Config.PhoneNumberCountryCode + phoneNumberLogin, token)
        .then((response: OTPResponse) => {
          Storage.setOTP(response);
          history.push("/loginotp/false");
        })
        .catch((ex) => {
          if (ex?.response?.status === 401) {
            setTimeout(() => {
              Storage.setPhoneNumberCatpcha(phoneNumberLogin as string, "true");
              loadReCaptcha(Config.ReCaptchaSiteKey);
            }, 100);
          } else if (ex?.response?.status === 400) {
            // history.push("/loginphonenumber/invalid");
            // Aux.setToastText(activeDictionary?.errors.e400);
            setError(true);
          } else if (ex?.response?.status === 403) {
            if (ex?.response?.data?.reason === REASON_MSISDN_NOT_ALLOWED) {
              // Aux.setToastText(activeDictionary?.errors.e403NotAllowed);
              // history.push("/loginphonenumber/invalid");
              setError(true);
            } else if (ex?.response?.data?.reason === REASON_INVALID_SIM_SWAP) {
              // Aux.setToastText(activeDictionary?.errors.e403SIMSwap);
              // history.push("/loginphonenumber/invalid");
              setError(true);
            } else {
              Aux.setToastText(activeDictionary?.errors.e403);
              history.push("/loginblocked/phonenumber/" + ex.response.data.blockedTimeLeft);
            }
          } else if (ex?.response?.status === 406) {
            setError(true);
            /* history.push("/loginphonenumber/invalid");
            if (ex?.response?.data?.reason === REASON_DISABLE) {
              Aux.setToastText(activeDictionary?.errors.e406Invalid);
            } else if (ex?.response?.data?.reason === REASON_NOT_FOUND) {
              Aux.setToastText(activeDictionary?.errors.e406NotFound);
            } else {
              Aux.setToastText(activeDictionary?.errors.e406);
            }*/
          } else {
            // Aux.setToastText(activeDictionary?.errors.generic);
            // history.push("/loginphonenumber/invalid");
            setError(true);
          }
        });
    }
    if (type === TYPE_OTP) {
      let otpResponse = Storage.getOTP() as OTPResponse;
      let phoneNumber = Config.PhoneNumberCountryCode + Storage.getPhoneNumberLogin();
      let language = Storage.getActiveLanguage();
      let otpLogin = Storage.getOtpLogin();

      Requests.validateOTP(otpLogin as string, otpResponse.id as string, phoneNumber as string, language as string, token)
        .then((response: OTPValidateResponse) => {
          Storage.setSession(response);
          Storage.setPhoneNumberCatpcha(phoneNumberLogin as string, "false");
          if (response.profile?.terms) history.push("/home");
          else history.push("/logintc");
        })
        .catch((ex) => {
          if (ex?.response?.status === 401) {
            if (ex?.response?.data?.reason === REASON_INVALID) {
              history.push("/loginotp/true");
            } else if (ex?.response?.data?.reason === REASON_NEED_RECAPTCHA) {
              setTimeout(() => {
                loadReCaptcha(Config.ReCaptchaSiteKey);
              }, 100);
            }
          } else if (ex?.response?.status === 403) {
            history.push("/loginblocked/otp/" + ex.response.data.blockedTimeLeft);
          } else {
            history.push("/loginotp/true");
          }
        });
    }
  }
}

export const LoginWait: React.FC = () => {
  const [activeDictionary, setActiveDictionary] = React.useState<any>();
  const [error, setError] = React.useState<any>(false);
  const history = useHistory();
  const { type } = useParams<Record<string, string | undefined>>();
  const phoneNumberLogin = Storage.getPhoneNumberLogin();
  const languageSelect = React.useRef<HTMLSelectElement>(null);
  const [timeLeft, { start }] = useCountDown(59 * 1000 * 1, 1000);

  Constructor(() => {
    switchLanguage();
  });

  React.useEffect(() => {
    makeRequests("", Storage.getPhoneNumberLogin() as string, type as string, history, activeDictionary, setError);
  }, [history, activeDictionary, type]);

  React.useEffect(() => {
    if (languageSelect.current) languageSelect.current.value = Storage.getActiveLanguage() as string;
    window.history.pushState(null, "", window.location.pathname);
    window.onpopstate = (e: any) => {
      history.goBack();
    };
    start();
  }, [history, start]);

  function onChangeLanguage(event: any) {
    Storage.setActiveLanguage(event.target.value);
    switchLanguage();
  }

  function switchLanguage() {
    switch (Storage.getActiveLanguage()) {
      case "en":
        setActiveDictionary(dictionary.en);
        break;
      case "pt":
        setActiveDictionary(dictionary.pt);
        break;
    }
  }

  function handleConfirmClick() {
    start(59 * 1000 * 1);
    makeRequests("", Storage.getPhoneNumberLogin() as string, type as string, history, activeDictionary, setError);
  }

  function handleChangeNumberClick() {
    history.push("/loginphonenumber");
  }

  function pad(i: number) {
    return ("0" + i).slice(-2);
  }

  function verifyCallback(token: any) {
    makeRequests(token, Storage.getPhoneNumberLogin() as string, type as string, history, activeDictionary, setError);
  }

  function render() {
    return (
      <div className="container">
        <ReCaptcha sitekey={Config.ReCaptchaSiteKey} action="submit" verifyCallback={verifyCallback} />
        <div className="row">
          <div className="col-sm leftSide">
            <div className="row justify-content-center">
              <img src={mainLogo6464} alt="Logo" className="login-logo" />
            </div>
            <div className="row justify-content-center">
              <div className="login-title">{activeDictionary?.loginPhoneNumber?.title}</div>
            </div>
            <div className="row justify-content-center">
              <div
                className="login-text"
                dangerouslySetInnerHTML={{ __html: activeDictionary?.loginWait?.text.replace("{phoneNumber}", "+" + Config.PhoneNumberCountryCode + phoneNumberLogin) }}
              ></div>
            </div>
            <div className="row justify-content-center">
              <div className="login-blocked-timer">
                {!error && timeLeft > 0 ? (
                  <span>
                    {pad(Math.floor(timeLeft / 60000))}:{pad((timeLeft % 60000) / 1000)}
                  </span>
                ) : (
                  <div className="login-blocked-resendtext">{activeDictionary?.loginWait.resendText}</div>
                )}
              </div>
            </div>
            <div className="row justify-content-center">
              <Button className="button login-confirm" onClick={handleConfirmClick} text={activeDictionary?.loginWait.button} disabled={!error && timeLeft > 0}></Button>
            </div>
            <div className="row justify-content-center">
              <div className="login-changePhoneNumber" onClick={handleChangeNumberClick}>
                {activeDictionary?.loginOTP?.changePhoneNumber}
              </div>
            </div>
            <div className="row justify-content-center">
              <select ref={languageSelect} className="login-language" onChange={onChangeLanguage}>
                <option value="en">English</option>
                <option value="pt">Português</option>
              </select>
            </div>
            <LoginFooter />
          </div>
          <div className="col-sm rightSide"></div>
        </div>
      </div>
    );
  }

  return <div className="App">{render()}</div>;
};

export default LoginWait;
