import React from "react";
import { useDispatch, useSelector } from "react-redux";

import mainLogo6464 from "../../assets/logo6464.png";
import "./loginLanguage.scss";
import Storage from "../../storage";
import { Requests } from "../../requests";
import { LoginFooter } from "./loginFooter";
import { useHistory } from "react-router-dom";

import { selectedDictionaryReducer } from "../../store/actions";
import { Aux } from "../../models";

export const LoginLanguage: React.FC = () => {
  const dictionary = useSelector((state: any) => state.selectedDictionary);
  const languageSelect = React.useRef<HTMLSelectElement>(null);
  const otpInput = React.useRef<HTMLInputElement>(null);
  const history = useHistory();
  const dispatch = useDispatch();

  React.useLayoutEffect(() => {
    otpInput.current?.focus();
    if (languageSelect.current) languageSelect.current.value = Storage.getActiveLanguage() as string;
  }, []);

  function handleLoginLanguageClick() {
    if (languageSelect.current) {
      Requests.setLanguage(languageSelect.current.value).then(() => {
        history.push("/home");
      }).catch(() => {
        history.push("/loginphonenumber");
        Aux.setToastText(dictionary?.errors.generic);
      });
    }
  }

  function onChangeLanguage(event: any) {
    Storage.setActiveLanguage(event.target.value);
    dispatch(selectedDictionaryReducer(event.target.value));
  }

  function loginNumber() {
    return (
      <div className="container">
        <div className="row">
          <div className="col-sm leftSide">
            <div className="row justify-content-center">
              <img src={mainLogo6464} alt="Logo" className="login-logo" />
            </div>
            <div className="row justify-content-center">
              <div className="login-title">{dictionary?.loginPhoneNumber?.title}</div>
            </div>
            <div className="row justify-content-center">
              <div className="login-text">{dictionary?.loginLanguage?.text}</div>
            </div>
            <div className="row justify-content-center">
              <div className="login-labelTop">{dictionary?.loginLanguage?.languageTop}</div>
              <select ref={languageSelect} className="login-language-input" onChange={onChangeLanguage}>
                <option value="en">English</option>
                <option value="pt">Português</option>
              </select>
            </div>
            <div className="row justify-content-center">
              <button className="button login-confirm" onClick={handleLoginLanguageClick}>
                {dictionary?.loginLanguage?.confirmButton}
              </button>
            </div>
            <LoginFooter/>
          </div>
          <div className="col-sm rightSide"></div>
        </div>
      </div>
    );
  }

  return <div className="App">{loginNumber()}</div>;
};

export default LoginLanguage;
