import { store } from "./index";
import { setToastText } from "./store/actions";

export enum Feature {
  Tickets,
  DataStatement
}

export interface OTPResponse {
  id?: string;
  otp?: string;
  otpStatus?: string;
  profileId?: string;
}

export interface OTPValidateResponse {
  accessToken?: string;
  profile?: Profile;
  refreshToken?: string;
}

export interface Profile {
  id?: string;
  language?: string;
  msisdn?: string;
  name?: string;
  tariff?: { type: string, name: string }
  terms?: true
}

export class DataCategory {
  id?: string;
  name?: any;
  selected?: boolean;
  dataUsed?: number;
  dataLimit?: number;
}

export class Inputs {
  static isValidEmail(email?: string) {
    if (email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    }
    return false;
  }
}

export class ConfigUtils {
  static isFeatureEnabled(feature: Feature) {
    return Config.FeatureSet.includes(Feature[feature]);
  }
}

export class Aux {
  static setToastText(text: string) {
    store.dispatch(setToastText(text));
    setTimeout(() => {
      store.dispatch(setToastText(""));
    }, 5000);
  }
  static addDays(date: Date, days: number) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }
  static handleErrors(history: any, ex: any, dic: any) {
    if (ex?.response?.status === 401) {
      Aux.setToastText(dic?.errors?.e401);
      history.push("/");
    }
  }
  static convertBytes(bytes: number, precision?: number) {
    return `${Aux.convertBytesValueOnly(bytes, precision)}  ${Aux.convertBytesUnitsOnly(bytes)}`;
  }

  static convertBytesValueOnly(bytes: number, precision?: number) {
    if (bytes === 0 || !bytes || isNaN(bytes)) {
      return Number(0).toFixed(precision || 0);
    }

    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    return (bytes / Math.pow(1024, i)).toFixed(precision || 1);
  }

  static convertBytesUnitsOnly(bytes: number) {
    const sizes = ["b", "Kb", "Mb", "Gb", "Tb"];
    if (bytes === 0 || !bytes || isNaN(bytes)) {
      return "Kb";
    }

    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    return sizes[i];
  }

  static camelize(str: string) {
    return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function (match, index) {
      if (+match === 0) return ""; // or if (/\s+/.test(match)) for white spaces
      return index === 0 ? match.toLowerCase() : match.toUpperCase();
    });
  }

  static setCookie(name: string, value: string, days: number) {
    var expires = "";
    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
  }
  static getCookie(name: string) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }
  static eraseCookie(name: string) {
    document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  }
}