import React from "react";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";

import mainLogo6464 from "../../assets/logo6464.png";
import rightImage from "../../assets/rightImage.png";
import menuCloseWhite from "../../assets/icons-close-red.svg";
import menuBack from "../../assets/icons-arrow-arrow-left.svg";
import "./newTicketTC.scss";

import { useHistory } from "react-router-dom";
import Menu, { MenuItems } from "../home/menu";

export const NewTicketTC: React.FC = () => {
  const dictionary = useSelector((state: any) => state.selectedDictionary);
  const confirmButtonTC = React.useRef<HTMLButtonElement>(null);
  const inputPP = React.useRef<HTMLInputElement>(null);
  const inputTC = React.useRef<HTMLInputElement>(null);
  const isDesktop = useMediaQuery({ query: "(min-width: 600px)" });
  const history = useHistory();

  React.useEffect(() => {
    if (confirmButtonTC.current) confirmButtonTC.current.disabled = true;
  }, []);

  React.useEffect(() => {
    window.history.pushState(null, "", window.location.pathname);
    window.onpopstate = (e: any) => {
      history.goBack();
    };
  }, [history]);

  function handleCloseClick() {
    history.push("/tickets");
  }

  function handleConfirmClick() {
    history.push("/newticketsubject");
  }

  function renderMobile() {
    return (
      <div className="container">
        <div className="row">
          <div className="newticket-tc-close">
            <img src={menuCloseWhite} alt="Logo" className="newticket-tc-close-img" onClick={handleCloseClick} />
          </div>
        </div>
        <div className="row">
          <div className="col-sm leftSide">
            <div className="row justify-content-center">
              <img src={mainLogo6464} alt="Logo" className="newticket-tc-logo" />
            </div>
            <div className="row justify-content-center">
              <div className="newticket-tc-title">{dictionary?.loginPhoneNumber?.title}</div>
            </div>
            <div className="row justify-content-center">
              <div className="newticket-tc-text">{dictionary?.loginTC?.text}</div>
            </div>
            <div className="row justify-content-center">
              <div className="newticket-tc-terms" dangerouslySetInnerHTML={{ __html: Config.TermsOfService[dictionary?.type] }}></div>
            </div>
            <div className="row justify-content-center newticket-tc-pp">
              <div className="col-1">
                <input
                  ref={inputPP}
                  type="checkbox"
                  id="agreeCheckbox"
                  className="newticket-tc-agree-cb"
                  onChange={(event: any) => {
                    if (event.target.checked && confirmButtonTC.current && inputTC.current?.checked) confirmButtonTC.current.disabled = false;
                    else if (confirmButtonTC.current) confirmButtonTC.current.disabled = true;
                  }}
                />
              </div>
              <div className="col-10 newticket-tc-agree-text"
                  dangerouslySetInnerHTML={{ __html: dictionary?.general.privacyPolicy.agreeText.replace("{href}", Config.PrivacyPolicyLink)}}></div>
            </div>
            <div className="row justify-content-center newticket-tc-tc">
              <div className="col-1">
                <input
                  ref={inputTC}
                  type="checkbox"
                  id="agreeCheckbox"
                  className="newticket-tc-agree-cb"
                  onChange={(event: any) => {
                    if (event.target.checked && confirmButtonTC.current && inputPP.current?.checked) confirmButtonTC.current.disabled = false;
                    else if (confirmButtonTC.current) confirmButtonTC.current.disabled = true;
                  }}
                />
              </div>
              <div className="col-10 newticket-tc-agree-text"
                  dangerouslySetInnerHTML={{ __html: dictionary?.general.termsAndConditions.agreeText.replace("{href}", Config.TermsAndConditionsLink)}}></div>
            </div>
            <div className="row justify-content-center">
              <button ref={confirmButtonTC} className="newticket-tc-confirm" onClick={handleConfirmClick}>
                {dictionary?.loginTC?.confirmButton}
              </button>
            </div>
          </div>
          <div className="col-sm rightSide">
            <img src={rightImage} alt="Logo" className="rightSideImage" />
          </div>
        </div>
      </div>
    );
  }

  function renderDesktop() {
    return (
      <div className="container">
        <div className="row main-row">
          <div className="support-data-left-div">
            <Menu selected={MenuItems.Tickets}></Menu>
          </div>
          <div className="support-data-main-div-full">
            <div className="container">
              <div className="row justify-content-center">
                <img
                  src={menuBack}
                  alt="Logo"
                  className="newticket-top-menu-back"
                  onClick={() => {
                    history.goBack();
                  }}
                />
              </div>
              <div className="row justify-content-center">
                <div className="newticket-tc-text">{dictionary?.loginTC?.text}</div>
              </div>
              <div className="row justify-content-center">
                <div className="newticket-tc-terms" dangerouslySetInnerHTML={{ __html: Config.TermsOfService[dictionary?.type] }}></div>
              </div>
              <div className="row justify-content-center newticket-tc-pp">
                <div className="col-1">
                  <input
                    ref={inputPP}
                    type="checkbox"
                    id="agreeCheckbox"
                    className="newticket-tc-agree-cb"
                    onChange={(event: any) => {
                      if (event.target.checked && confirmButtonTC.current && inputTC.current?.checked) confirmButtonTC.current.disabled = false;
                      else if (confirmButtonTC.current) confirmButtonTC.current.disabled = true;
                    }}
                  />
                </div>
                <div className="col-10 newticket-tc-agree-text"
                  dangerouslySetInnerHTML={{ __html: dictionary?.general.privacyPolicy.agreeText.replace("{href}", Config.PrivacyPolicyLink)}}>
                </div>
              </div>
              <div className="row justify-content-center newticket-tc-tc">
                <div className="col-1">
                  <input
                    ref={inputTC}
                    type="checkbox"
                    id="agreeCheckbox"
                    className="newticket-tc-agree-cb"
                    onChange={(event: any) => {
                      if (event.target.checked && confirmButtonTC.current && inputPP.current?.checked) confirmButtonTC.current.disabled = false;
                      else if (confirmButtonTC.current) confirmButtonTC.current.disabled = true;
                    }}
                  />
                </div>
                <div className="col-10 newticket-tc-agree-text"
                  dangerouslySetInnerHTML={{ __html: dictionary?.general.termsAndConditions.agreeText.replace("{href}", Config.TermsAndConditionsLink)}}>
                </div>
              </div>
              <div className="row justify-content-center">
                <button ref={confirmButtonTC} className="newticket-tc-confirm" onClick={handleConfirmClick}>
                  {dictionary?.loginTC?.confirmButton}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  function render() {
    return isDesktop ? renderDesktop() : renderMobile();
  }

  return <div className="App">{render()}</div>;
};

export default NewTicketTC;
