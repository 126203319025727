import React from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Storage from "../../storage";
import { Requests } from "../../requests";
import mainLogo from "./../../assets/logos-vodafone-red.svg";
import iconsUser from "./../../assets/icons-user.svg";
import iconsHome from "./../../assets/icons-home-thin.svg";
import iconsHomeRed from "./../../assets/icons-home-thin-red.svg";
import iconsMobileData from "./../../assets/icons-mobiledata-thick.svg";
import iconsMobileDataRed from "./../../assets/icons-mobiledata-thick-red.svg";
import iconsTickets from "./../../assets/icons-assistance-thick.svg";
import iconsTicketsRed from "./../../assets/icons-assistance-thick-red.svg";
import iconsLogout from "./../../assets/icons-logout.svg";
import { store } from "../../index";
import { selectedDictionaryReducer, setShowLogout } from "../../store/actions";

import { ConfigUtils, Feature } from "../../models";

import "./menu.scss";

export enum MenuItems {
  Home,
  Data,
  Tickets,
}

interface MenuProps {
  selected: MenuItems;
}

export const Menu: React.FC<MenuProps> = ({ selected }) => {
  const languageSelect = React.useRef<HTMLSelectElement>(null);
  const history = useHistory();
  const login = Storage.getSession();
  const dispatch = useDispatch();

  const dictionary = useSelector((state: any) => state.selectedDictionary);

  React.useEffect(() => {
    if (languageSelect.current) languageSelect.current.value = Storage.getActiveLanguage() as string;
  }, [history]);

  function handleHomeClick() {
    history.push("/home");
  }

  function handleLogout() {
    store.dispatch(setShowLogout(true));
  }

  function onChangeLanguage(event: any) {
    Storage.setActiveLanguage(event.target.value);
    Requests.setLanguage(event.target.value)
    dispatch(selectedDictionaryReducer(event.target.value));
  }

  function render() {
    return (
      <div className="container menu-main-div">
        <div className="row menu-main-logo-container" onClick={handleHomeClick}>
          <img src={mainLogo} alt="Logo" className="menu-main-logo" />
          <span className="menu-main-logo-text">{dictionary?.homeMenu?.title}</span>
        </div>
        <div className="row menu-main-user-container">
          <div className="menu-main-user-logo-container">
            <img src={iconsUser} alt="Logo" className="menu-main-user-logo" />
          </div>
          <span className="menu-main-user-text">+{login?.profile?.msisdn}</span>
        </div>
        <div className="row menu-main-links-container">
          <div className="row menu-main-links-item" onClick={() => history.push("/home")}>
            <img src={selected === MenuItems.Home ? iconsHomeRed : iconsHome} alt="Logo" className="menu-main-links-item-logo" />
            <span className={selected === MenuItems.Home ? "menu-main-links-item-text-selected" : "menu-main-links-item-text"}>{dictionary?.homeMenu?.home}</span>
          </div>
          {
            ConfigUtils.isFeatureEnabled(Feature.DataStatement) &&
              <div className="row menu-main-links-item" onClick={() => history.push("/data")}>
                <img src={selected === MenuItems.Data ? iconsMobileDataRed : iconsMobileData} alt="Logo" className="menu-main-links-item-logo" />
                <span className={selected === MenuItems.Data ? "menu-main-links-item-text-selected" : "menu-main-links-item-text"}>{dictionary?.homeMenu?.mobileData}</span>
              </div>
          }
          {
            ConfigUtils.isFeatureEnabled(Feature.Tickets) &&
              <div className="row menu-main-links-item" onClick={() => history.push("/tickets")}>
                <img src={selected === MenuItems.Tickets ? iconsTicketsRed : iconsTickets} alt="Logo" className="menu-main-links-item-logo" />
                <span className={selected === MenuItems.Tickets ? "menu-main-links-item-text-selected" : "menu-main-links-item-text"}>{dictionary?.homeMenu?.tickets}</span>
              </div>
          }
        </div>
        <div className="row menu-main-language-container">
          <select ref={languageSelect} className="menu-main-language" onChange={onChangeLanguage}>
            <option value="en">English</option>
            <option value="pt">Português</option>
          </select>
        </div>
        <div
          className="row menu-main-logout-container"
          onClick={() => {
            handleLogout();
          }}
        >
          <img src={iconsLogout} alt="Logo" className="menu-main-logout" />
          <span className="menu-main-logout-text">{dictionary?.homeMenu?.logout}</span>
        </div>
        <div className="row menu-main-pth-container">
          <div className="row menu-main-pth-text">
            <a href={Config.PrivacyPolicyLink} target="_blank" rel="noreferrer">
              {dictionary?.general?.privacyPolicy.label}
            </a>
          </div>
          <div className="row menu-main-pth-text">
            <a href={Config.TermsAndConditionsLink} target="_blank" rel="noreferrer">
              {dictionary?.general?.termsAndConditions.label}
            </a>
          </div>
          <div className="row menu-main-pth-text">
            <a href={Config.FAQLink} target="_blank" rel="noreferrer">
              {dictionary?.general?.helpFAQs}
            </a>
          </div>
        </div>
      </div>
    );
  }

  return <div className="App">{render()}</div>;
};

export default Menu;
