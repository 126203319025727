import React from "react";
import mainLogo6464 from "../../assets/logo6464.png";
import { useHistory } from "react-router-dom";

import "./loginPhoneNumber.scss";

import Storage from "../../storage";
import Button from "../../components/button";
import LoginFooter from "./loginFooter";

import { selectedDictionaryReducer } from "../../store/actions";
import { useDispatch, useSelector } from "react-redux";

const PHONE_NUMBER_MIN_DIGITS = 9;
const PHONE_NUMBER_MAX_DIGITS = 9;

export const LoginPhoneNumber: React.FC = () => {
  const [phoneNumber, setPhoneNumber] = React.useState<string>(""); //84123456
  const [showPhoneNumberLabel, setShowPhoneNumberLabel] = React.useState(true); //84123456
  const phoneNumberInput = React.useRef<HTMLInputElement>(null);
  const [confirmButtonDisabled, setConfirmButtonDisabled] = React.useState<boolean>(true);
  const languageSelect = React.useRef<HTMLSelectElement>(null);
  const dictionary = useSelector((state: any) => state.selectedDictionary);
  const dispatch = useDispatch();
  const history = useHistory();

  React.useLayoutEffect(() => {
    if (languageSelect.current) languageSelect.current.value = Storage.getActiveLanguage() as string;
    let phoneNumber = Storage.getPhoneNumberLogin();
    if (phoneNumber && phoneNumber.length > 0) {
      setPhoneNumber(phoneNumber);
      onChangePhoneNumber(phoneNumber);
    }
    phoneNumberInput.current?.focus();
  }, []);

  function handleLoginNumberClick() {
    Storage.setPhoneNumberLogin(phoneNumber);
    history.push("/loginwait/phonenumber");
    setConfirmButtonDisabled(true);
  }

  function onChangePhoneNumberEvent(event: any) {
    onChangePhoneNumber(event.target.value);
  }

  function onChangePhoneNumber(phoneNumber: string) {
    const isValidPhoneNumber = phoneNumber.length >= PHONE_NUMBER_MIN_DIGITS;
    setConfirmButtonDisabled(!isValidPhoneNumber);
    setShowPhoneNumberLabel(!isValidPhoneNumber);
    setPhoneNumber(phoneNumber);
  }

  function onChangeLanguage(event: any) {
    Storage.setActiveLanguage(event.target.value);
    dispatch(selectedDictionaryReducer(event.target.value));
  }

  function render() {
    return (
      <div className="App">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm leftSide">
              <div className="row justify-content-center">
                <img src={mainLogo6464} alt="Logo" className="login-logo" />
              </div>
              <div className="row justify-content-center">
                <div className="login-title">{dictionary?.loginPhoneNumber?.title}</div>
              </div>
              <div className="row justify-content-center">
                <div className="login-text">{dictionary?.loginPhoneNumber?.text}</div>
              </div>
              <div className="row justify-content-center">
                <div className="login-labelTop" dangerouslySetInnerHTML={{ __html: dictionary?.loginPhoneNumber?.phoneLabelTop }}></div>
                <div className="login-phoneNumber-InputContainer">
                  <input
                    ref={phoneNumberInput}
                    className="login-phoneNumber"
                    type="number"
                    id="phone"
                    name="phone"
                    maxLength={PHONE_NUMBER_MAX_DIGITS}
                    onInput={(event: any) => {
                      if (event.target.value.length > event.target.maxLength) event.target.value = event.target.value.slice(0, event.target.maxLength);
                    }}
                    value={phoneNumber}
                    autoComplete="off"
                    onKeyDown={(event: any) => {
                      if (event.keyCode === 13 && event.target.value.length >= PHONE_NUMBER_MIN_DIGITS) handleLoginNumberClick();
                    }}
                    onChange={onChangePhoneNumberEvent}
                  ></input>
                </div>

                {showPhoneNumberLabel ? <div className="login-labelBottom" dangerouslySetInnerHTML={{ __html: dictionary?.loginPhoneNumber?.phoneLabelBottom }}></div> : null}
              </div>
              <div className="row justify-content-center">
                <Button className="button login-confirm" text={dictionary?.loginPhoneNumber?.confirmButton} disabled={confirmButtonDisabled} onClick={handleLoginNumberClick} />
              </div>
              <div className="row justify-content-center">
                <select ref={languageSelect} className="login-language" onChange={onChangeLanguage}>
                  <option value="en">English</option>
                  <option value="pt">Português</option>
                </select>
              </div>
              <LoginFooter />
            </div>
            <div className="col-sm rightSide"></div>
          </div>
        </div>
      </div>
    );
  }

  return render();
};

export default LoginPhoneNumber;
