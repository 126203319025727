import axios from "axios";
import Storage from "./storage";
import { format } from "date-fns";
import differenceInCalendarDay from "date-fns/differenceInCalendarDays";
import {
  OTPResponse,
  OTPValidateResponse,
} from "./models";


export class Requests {
  private static otp: OTPValidateResponse | null = null;
  private static serverUrl: string = Config.ServerUrl[0];

  private static normalizePhoneNumber(phoneNumber: string) {
    return phoneNumber.replace(/^\+/, "");
  }

  public static _initialize() {
    let urlParams = new URLSearchParams(window.location.search);
    let urlIndex = urlParams.get("urlIndex");
    if (urlIndex) this.serverUrl = Config.ServerUrl[parseInt(urlIndex as string)];
  }

  public static async getOTP(phoneNumber: string, token: string): Promise<OTPResponse> {
    const data = {
      "msisdn": Requests.normalizePhoneNumber(phoneNumber),
      "token": token
    };
    const headers = {
      "x-api-key-assistance-api": Config.AssistanceAPISecretKey
    }
    return (axios
      .post(this.serverUrl + "authenticate/otp/generate", data, {
        headers: headers,
        timeout: 30000
      }
      ))
      .then((response: any) => {
        return response.data;
      });
  }

  public static validateOTP(otp: string, otpId: string, phoneNumber: string, language: string, token: string): Promise<OTPValidateResponse> {
    const data = {
      "id": otpId,
      "language": language,
      "msisdn": Requests.normalizePhoneNumber(phoneNumber),
      "otp": otp,
      "token": token
    };
    const headers = {
      "x-api-key-assistance-api": Config.AssistanceAPISecretKey
    }
    return (axios
      .post(this.serverUrl + "authenticate/otp/validate", data, {
        headers: headers,
        timeout: 30000
      }
      ))
      .then((response: any) => {
        return response.data;
      });
  }

  public static validateOutofband(outofband: string) {
    const headers = {
      "x-api-key-assistance-api": Config.AssistanceAPISecretKey
    }
    return (axios
      .get(this.serverUrl + "outofband/validate/" + outofband, {
        headers: headers,
        timeout: 30000
      }
      ))
      .then((response: any) => {
        Storage.setSession(response.data);
        return response.data;
      });
  }

  public static async refreshToken() {
    const responseRefresh = await axios
      .get(this.serverUrl + "authorize/refresh", {
        headers: {
          "x-api-key-assistance-api": Config.AssistanceAPISecretKey,
          "refreshToken": "Bearer " + Requests.otp?.refreshToken as string
        },
        timeout: 30000
      },
      );
    if (Requests.otp) {
      Requests.otp.accessToken = responseRefresh.headers["x-custom-access-token"];
      Storage.setSession(Requests.otp);
    }
    return responseRefresh;
  }

  public static async doRequest(dataFunction: () => void) {
    this.otp = Storage.getSession();

    try {
      let resp = await dataFunction();
      return resp;
    }
    catch (ex: any) {
      if (ex.response.status === 401 && Requests.otp) {
        await this.refreshToken();
        let resp = await dataFunction();
        return resp;
      }
      else throw ex;
    }
  }

  public static checkSession(accessToken: string): Promise<any> {
    if (accessToken) {
      return this.doRequest(async () => {

        const resp = await axios.get(this.serverUrl + "authorize/validate", {
          headers: {
            "x-api-key-assistance-api": Config.AssistanceAPISecretKey,
            "Authorization": "Bearer " + accessToken
          },
          timeout: 30000
        },
        );

        return resp.data;

      });
    } else {
      return new Promise((resolve, reject) => {
        reject(Error("No active session"));
      });
    }
  }

  public static getProfile(accessToken: string): Promise<any> {
    if (accessToken) {
      return this.doRequest(async () => {

        const resp = await axios.get(this.serverUrl + "profile/info", {
          headers: {
            "x-api-key-assistance-api": Config.AssistanceAPISecretKey,
            "Authorization": "Bearer " + accessToken
          },
          timeout: 30000
        },
        );

        return resp.data;

      });
    } else {
      return new Promise((resolve, reject) => {
        reject(Error("No active session"));
      });
    }
  }

  public static async getTickets(startDate: Date, endDate: Date, start: number, size: number, status: string[]): Promise<any> {

    return this.doRequest(async () => {
      const data = {
        "endDate": format(endDate, "yyyy-MM-dd'T'23:59:59"),
        "size": size,
        "start": start,
        "startDate": format(startDate, "yyyy-MM-dd'T'00:00:00"),
        "status": status
      };
      const headers = {
        "x-api-key-assistance-api": Config.AssistanceAPISecretKey,
        "Authorization": "Bearer " + Requests.otp?.accessToken as string
      }
      const resp = await axios.post(this.serverUrl + "tickets/list", data, {
        headers: headers,
        timeout: 30000
      }
      );
      return resp.data;
    });
  }

  public static getTicketsTypes(): Promise<any> {
    return this.doRequest(async () => {
      const resp = await axios.get(this.serverUrl + "tickets/types/list", {
        headers: {
          "x-api-key-assistance-api": Config.AssistanceAPISecretKey,
          "Authorization": "Bearer " + Requests.otp?.accessToken as string
        },
        timeout: 30000
      },
      );
      return resp.data;
    });
  }

  public static async newTicket(initialGroup: string, type: string, item: string, summary: string, description: string): Promise<any> {

    return this.doRequest(async () => {
      const data = {
        "initialGroup": initialGroup,
        "type": type,
        "item": item,
        "summary": summary,
        "requestDescription": description
      };
      const headers = {
        "x-api-key-assistance-api": Config.AssistanceAPISecretKey,
        "Authorization": "Bearer " + Requests.otp?.accessToken as string
      }
      const resp = await axios.post(this.serverUrl + "tickets/submit", data, {
        headers: headers,
        timeout: 30000
      }
      );
      return resp.data;
    });
  }

  public static getCategories(): Promise<any> {
    return this.doRequest(async () => {
      const resp = await axios.get(this.serverUrl + "data/categories/all", {
        headers: {
          "x-api-key-assistance-api": Config.AssistanceAPISecretKey,
          "Authorization": "Bearer " + Requests.otp?.accessToken as string
        },
        timeout: 30000
      },
      );
      return resp.data;
    });
  }

  public static getCategoriesUsage(categories: string[], startDate: Date, endDate: Date): Promise<any> {
    return this.doRequest(async () => {

      const data = {
        "categoriesId": categories,
        "startPeriodDate": format(startDate, "yyyy-MM-dd'T'00:00:00"),
        "endPeriodDate": format(endDate, "yyyy-MM-dd'T'23:59:59"),
        "size": 2147483647
      };
      const headers = {
        "x-api-key-assistance-api": Config.AssistanceAPISecretKey,
        "Authorization": "Bearer " + Requests.otp?.accessToken as string
      }
      const resp = await axios.post(this.serverUrl + "data/usage/all", data, {
        headers: headers,
        timeout: 30000
      });
      return resp.data;
    });
  }

  public static getCategoriesDailyUsage(categories: string[], startDate: Date, endDate: Date): Promise<any> {
    return this.doRequest(async () => {

      const data = {
        "categoriesId": categories,
        "startPeriodDate": format(startDate, "yyyy-MM-dd'T'00:00:00"),
        "endPeriodDate": format(endDate, "yyyy-MM-dd'T'23:59:59"),
        "size": differenceInCalendarDay(endDate, startDate) + 1
      };
      const headers = {
        "x-api-key-assistance-api": Config.AssistanceAPISecretKey,
        "Authorization": "Bearer " + Requests.otp?.accessToken as string
      }
      const resp = await axios.post(this.serverUrl + "data/usage/daily", data, {
        headers: headers,
        timeout: 30000
      }
      );
      return resp.data;
    });
  }

  public static getCategoriesHourlyUsage(categories: string[], singleDate: Date): Promise<any> {
    return this.doRequest(async () => {

      const data = {
        "categoriesId": categories,
        "day": format(singleDate, "yyyy-MM-dd"),
        "size": 24
      };
      const headers = {
        "x-api-key-assistance-api": Config.AssistanceAPISecretKey,
        "Authorization": "Bearer " + Requests.otp?.accessToken as string
      }
      const resp = await axios.post(this.serverUrl + "data/usage/hourly", data, {
        headers: headers,
        timeout: 30000
      }
      );
      return resp.data;
    });
  }

  public static setLanguage(lang: string): Promise<any> {
    return this.doRequest(async () => {

      const headers = {
        "x-api-key-assistance-api": Config.AssistanceAPISecretKey,
        "Authorization": "Bearer " + Requests.otp?.accessToken as string
      }
      const resp = await axios
        .put(this.serverUrl + "profile/language/" + lang, {}, {
          headers: headers,
          timeout: 30000
        });
      return resp.data;
    });
  }

  public static setTermsAndConditions(): Promise<any> {
    return this.doRequest(async () => {

      const headers = {
        "x-api-key-assistance-api": Config.AssistanceAPISecretKey,
        "Authorization": "Bearer " + Requests.otp?.accessToken as string
      }
      const resp = await axios
        .put(this.serverUrl + "profile/terms/accept", {}, {
          headers: headers,
          timeout: 30000
        });
      return resp.data;
    });
  }
}
Requests._initialize();
export default Requests;
