import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

import Requests from "../../requests";
import { Aux } from "../../models";

import iconsRightArrow from "../../assets/icons-chevron-right-blue.svg";

import "./ticketTypesList.scss";

interface TicketTypesListProps {
  onItemClick: (ticketType: any) => void;
  getIcon: (ticketType: any) => string;
}

export const TicketTypesList: React.FC<TicketTypesListProps> = ({ onItemClick, getIcon }) => {
  const [ticketTypeList, setTicketTypeList] = React.useState<[any]>();
  const dictionary = useSelector((state: any) => state.selectedDictionary);
  const history = useHistory();
  const [loading, setLoading] = React.useState<boolean>();
  const isDesktop = useMediaQuery({ query: "(min-width: 600px)" });
  const loadingItemCount = 3;

  React.useLayoutEffect(() => {
    setLoading(true);
    Requests.getTicketsTypes()
      .then((responseTypes: any) => {
        setTicketTypeList(responseTypes.types);
        setLoading(false);
      }).catch((ex: any) => {
        setLoading(false);
        Aux.setToastText(dictionary?.errors.generic);
        Aux.handleErrors(history, ex, dictionary);
      });
  }, [dictionary, history]);

  function handleClick(item: any) {
    onItemClick(item);
  }

  function renderMobile() {
    return (
      <div className="row justify-content-center ticket-type-container">
        {ticketTypeList?.map((item: any, index: number) => (
          <div key={`row${index}`} className="row justify-content-center">
            <button className="newticket-type" onClick={() => handleClick(item)}>
              {item.name[dictionary?.type]}
            </button>
          </div>
        ))}
      </div>);
  }

  function renderDesktop() {
    return (
      <div className="row justify-content-center ticket-type-container">
        {loading ? renderLoading() : 
        ticketTypeList?.map((item: any, index: number) => (
          <div key={`row${index}`} className="row justify-content-center">
            <div className="content-card" onClick={() => handleClick(item)}>
              <img src={getIcon(item)} alt="Type Logo" className="content-card-item-logo" />
              <div className="content-card-item-label">{item.name[dictionary?.type]}</div>
              <div className="content-card-item-action" onClick={() => handleClick(item)}>
                <img src={iconsRightArrow} alt="Logo" />
              </div>
            </div>
          </div>
        ))}
      </div>);
  }

  function renderLoading() {
    return (
      <div>
        {Array.from(Array(loadingItemCount).keys()).map((index: number) =>
        <div key={`row${index}`} className="content-card">
          <div className="content-card-item-logo loading-placeholder"></div>
          <div className="content-card-item-label loading-placeholder">&nbsp;</div>
          <div className="content-card-item-action loading-placeholder"></div>
        </div>)}
      </div>
    );
  }

  function render() {
    return isDesktop ? renderDesktop() : renderMobile();
  }

  return render();
};

export default TicketTypesList;
