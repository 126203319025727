import React from "react";
import { useSelector } from "react-redux";

import mainLogo6464 from "../../assets/logo6464.png";
import "./login.scss";
import "./loginTC.scss";

import { useHistory } from "react-router-dom";
import Requests from "../../requests";
import { Aux } from "../../models";
import { Button } from "../../components/button";

export const LoginTC: React.FC = () => {
  const dictionary = useSelector((state: any) => state.selectedDictionary);
  const [privacyPolicyCheck, setPrivacyPolicyCheck] = React.useState<boolean>(false);
  const [termsAndConditionsCheck, setTermsAndConditionsCheck] = React.useState<boolean>(false);
  const [confirmButtonLoading, setConfirmButtonLoading] = React.useState<boolean>(false);
  const history = useHistory();

  React.useEffect(() => {
    window.history.pushState(null, "", window.location.pathname);
    window.onpopstate = (e: any) => {
      history.goBack();
    };
  }, [history]);

  function handleConfirmClick() {
    setConfirmButtonLoading(true);
    Requests.setTermsAndConditions()
      .then(() => {
        setConfirmButtonLoading(false);
        history.push("/loginLanguage");
      }).catch(() => {
        Aux.setToastText(dictionary?.errors.generic);
        setConfirmButtonLoading(false);
      });
  }

  function render() {
    return (
      <div className="container">
        <div className="row">
          <div className="col-sm leftSide">
            <div className="row justify-content-center">
              <img src={mainLogo6464} alt="Logo" className="login-logo" />
            </div>
            <div className="row justify-content-center">
              <div className="login-title">{dictionary?.loginPhoneNumber?.title}</div>
            </div>
            <div className="row justify-content-center">
              <div className="login-tc-title">{dictionary?.loginTC?.text}</div>
            </div>
            <div className="row justify-content-center">
              <div className="login-tc-terms" dangerouslySetInnerHTML={{ __html: Config.TermsOfService[dictionary?.type] }}></div>
            </div>
            <div className="row justify-content-center">
              <div className="login-pp-ct">
                <input
                  type="checkbox"
                  id="agreeCheckbox"
                  className="login-tc-agree-cb"
                  onChange={(event: any) => setPrivacyPolicyCheck(event.target.checked)}
                />
                <div className="col-10 login-tc-agree-text"
                  dangerouslySetInnerHTML={{__html: dictionary?.general.privacyPolicy.agreeText.replace("{href}", Config.PrivacyPolicyLink)}}></div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="login-tc-ct">
                <input
                  type="checkbox"
                  id="agreeCheckbox"
                  className="login-tc-agree-cb"
                  onChange={(event: any) => setTermsAndConditionsCheck(event.target.checked)}
                />
                <div className="col-10 login-tc-agree-text"
                  dangerouslySetInnerHTML={{__html: dictionary?.general.termsAndConditions.agreeText.replace("{href}", Config.TermsAndConditionsLink)}}></div>
              </div>
            </div>
            <div className="row justify-content-center">
              <Button className="button login-tc-confirm"
                text={dictionary?.loginTC?.confirmButton}
                disabled={!privacyPolicyCheck || !termsAndConditionsCheck}
                loading={confirmButtonLoading}
                onClick={handleConfirmClick}>
              </Button>
            </div>
          </div>
          <div className="col-sm rightSide"></div>
        </div>
      </div>
    );
  }

  return <div className="App">{render()}</div>;
};

export default LoginTC;
