import React from "react";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";

import mainLogoRed from "../../assets/logos-vodafone-red.svg";
import menuCloseWhite from "../../assets/icons-close-red.svg";
import menuBack from "../../assets/icons-arrow-arrow-left.svg";
import "./newTicket.scss";
import "./newTicketEmail.scss";

import { useHistory } from "react-router-dom";
import Storage from "../../storage";
import Menu, { MenuItems } from "../home/menu";
import { Button } from "../../components/button";
import { Aux, Inputs } from "../../models";
import { PersonalDataItem } from "../../model/tickets";

export const NewTicketSubject: React.FC = () => {
  const dictionary = useSelector((state: any) => state.selectedDictionary);
  const history = useHistory();
  const isDesktop = useMediaQuery({ query: "(min-width: 600px)" });
  const inputEmail = React.useRef<HTMLInputElement>(null);
  const [continueButtonDisabled, setContinueButtonDisabled] = React.useState<boolean>(true);

  React.useEffect(() => {
    let emailStored = Storage.getNewTicketEmail() as string;
    if (Inputs.isValidEmail(emailStored)) {
      setContinueButtonDisabled(false);
      if (inputEmail.current) inputEmail.current.value = emailStored;
    } else {
      setContinueButtonDisabled(true);
      if (inputEmail.current) inputEmail.current.value = emailStored;
    }

    window.history.pushState(null, "", window.location.pathname);
    window.onpopstate = (e: any) => {
      history.goBack();
    };
  }, [history]);

  function handleCloseClick() {
    history.push("/tickets");
  }

  function handleContinueClick() {
    Storage.setNewTicketEmail(inputEmail?.current?.value as string);
    history.push("/newticketconfirm/pending");
  }

  function getTitle() {
    const subject = Storage.getNewTicketSubject() as string;
    const title = subject ? dictionary?.tickets?.newTicketSubject[Aux.camelize(subject)] : undefined;
    return title ? title : dictionary?.tickets?.newTicketEmail?.text;
  }

  function getSubText() {
    const item = Storage.getNewTicketItem() as PersonalDataItem;
    return dictionary?.tickets.personalData[item].emailSubText;
  }

  function renderMobile() {
    return (
      <div className="container">
        <div className="row">
          <div className="newticket-top-menu-left">
            <img src={mainLogoRed} alt="Logo" className="newticket-top-menu-logo" />
          </div>
          <div className="newticket-top-menu-midle">{dictionary?.tickets?.newTicketType?.title}</div>
          <div className="newticket-top-menu-right">
            <img src={menuCloseWhite} alt="Logo" className="newticket-top-menu-close" onClick={handleCloseClick} />
          </div>
        </div>
        <div className="row justify-content-center newticket-text">{dictionary?.tickets?.newTicketEmail?.text}</div>
        <div className="row justify-content-center">
          <div className="newticket-subText">{getSubText()}</div>
        </div>
        <div className="row justify-content-center">
          <div className="newTicketEmail-labelTop">{dictionary?.tickets.newTicketEmail.emailTopLabel}</div>
          <input
            ref={inputEmail}
            className="newTicketEmail-email"
            type="email"
            placeholder="example@example.com"
            onChange={(event: any) => {
              setContinueButtonDisabled(!Inputs.isValidEmail(event.target.value));
            }}
          ></input>
          {/* <div className="newTicketEmail-labelBottom">{dictionary?.tickets?.newTicketEmail?.emailBottomLabel}</div> */}
        </div>
        <div className="row justify-content-center">
          <Button className="button newTicket-continueButton" text={dictionary?.general.buttons.continue} disabled={continueButtonDisabled} onClick={handleContinueClick}></Button>
        </div>
      </div>
    );
  }

  function renderDesktop() {
    return (
      <div className="container">
        <div className="row main-row">
          <div className="support-data-left-div">
            <Menu selected={MenuItems.Tickets}></Menu>
          </div>
          <div className="support-data-main-div-full">
            <div className="container">
              <div className="row justify-content-center">
                <img
                  src={menuBack}
                  alt="Logo"
                  className="newticket-top-menu-back"
                  onClick={() => {
                    history.goBack();
                  }}
                />
              </div>
              <div className="row justify-content-center">
                <div className="newticket-text">{getTitle()}</div>
              </div>
              <div className="row justify-content-center">
                <div className="newticket-subText">{getSubText()}</div>
              </div>
              <div className="row justify-content-center">
                <div className="newTicketEmail-labelTop">{dictionary?.tickets?.newTicketEmail?.emailTopLabel}</div>
                <input
                  ref={inputEmail}
                  className="newTicketEmail-email"
                  type="email"
                  placeholder="example@example.com"
                  onChange={(event: any) => {
                    setContinueButtonDisabled(!Inputs.isValidEmail(event.target.value));
                  }}
                ></input>
                <div className="newTicketEmail-labelBottom">{dictionary?.tickets?.newTicketEmail?.emailBottomLabel}</div>
              </div>
              <div className="row justify-content-center">
                <Button className="button newTicket-continueButton" text={dictionary?.general.buttons.continue} disabled={continueButtonDisabled} onClick={handleContinueClick}></Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  function render() {
    return isDesktop ? renderDesktop() : renderMobile();
  }

  return <div className="App">{render()}</div>;
};

export default NewTicketSubject;
