import React from "react";
import { useDispatch, useSelector } from "react-redux";

import mainLogo6464 from "../../assets/logo6464.png";
import "./login.scss";
import "./loginOTP.scss";
import { useHistory, useParams } from "react-router-dom";
import Storage from "../../storage";
import Button from "../../components/button";
import { selectedDictionaryReducer } from "../../store/actions";
import { OTPResponse, OTPValidateResponse } from "../../models";
import { Requests } from "../../requests";
import { LoginFooter } from "./loginFooter";
import { loadReCaptcha, ReCaptcha } from "react-recaptcha-v3";

const REASON_NEED_RECAPTCHA = "NEED_RECAPTCHA";
const REASON_INVALID = "INVALID";
const REASON_EXPIRED = "EXPIRED";

interface ParamTypes {
  error: string;
}

export const LoginOTP: React.FC = () => {
  const phoneNumberLogin = Storage.getPhoneNumberLogin();
  const [otpNumber, setOTPNumber] = React.useState<string>("");
  const [confirmButtonDisabled, setConfirmButtonDisabled] = React.useState<boolean>(true);
  const [confirmButtonLoading, setConfirmButtonLoading] = React.useState<boolean>(false);
  const { error } = useParams<ParamTypes>();
  const [otpError, setOTPError] = React.useState<boolean>(error === "true" ? true : false);
  const otpInput = React.useRef<HTMLInputElement>(null);
  const languageSelect = React.useRef<HTMLSelectElement>(null);
  const dictionary = useSelector((state: any) => state.selectedDictionary);
  const dispatch = useDispatch();
  const history = useHistory();
  const phoneNumber: string = "+" + Config.PhoneNumberCountryCode + phoneNumberLogin;

  React.useLayoutEffect(() => {
    if (languageSelect.current) languageSelect.current.value = Storage.getActiveLanguage() as string;
    otpInput.current?.focus();
    setConfirmButtonDisabled(true);
  }, [history]);

  React.useEffect(() => {
    window.history.pushState(null, "", window.location.pathname);
    window.onpopstate = (e: any) => {
      history.goBack();
    };
  }, [history]);

  function handleChangeInput(event: any) {
    const { value, maxLength } = event.target;
    const message = value.slice(0, maxLength);
    setOTPNumber(message);
    setConfirmButtonDisabled(event.currentTarget.value.length <= 5);
  }
  
  function verifyCallback() {
    handleLoginOTPClick();
  }

  function handleLoginOTPClick() {
    Storage.setOtpLogin(otpNumber);
    setConfirmButtonLoading(true);
    setOTPError(false);

    let otpResponse: OTPResponse = Storage.getOTP() as OTPResponse;
    let phoneNumber: string = Config.PhoneNumberCountryCode + phoneNumberLogin;
    let language: string = Storage.getActiveLanguage() as string;
    let otpLogin: string = Storage.getOtpLogin() as string;

    Requests.validateOTP(otpLogin, otpResponse.id as string, phoneNumber, language, otpNumber)
      .then((response: OTPValidateResponse) => {
        Storage.setSession(response);
        Storage.setPhoneNumberCatpcha(phoneNumberLogin as string, "false");
        if (response.profile?.terms) history.push("/home");
        else history.push("/logintc");
      })
      .catch((ex) => {
        setConfirmButtonLoading(false);

        if (ex?.response?.status === 401) {
          if (ex?.response?.data?.reason === REASON_INVALID ||
                ex?.response?.data?.reason === REASON_EXPIRED) {
            setOTPError(true);
          } else if (ex?.response?.data?.reason === REASON_NEED_RECAPTCHA) {
            setTimeout(() => {
              loadReCaptcha(Config.ReCaptchaSiteKey);
            }, 100);
          }
        } else if (ex?.response?.status === 403) {
          history.push("/loginblocked/otp/" + ex.response.data.blockedTimeLeft);
        } else {
          setOTPError(true);
        }
      });
  }

  function handleLoginNumberClick() {
    history.push("/loginwait/phonenumber");
  }

  function handleChangeNumberClick() {
    history.push("/loginphonenumber");
  }

  function onChangeLanguage(event: any) {
    Storage.setActiveLanguage(event.target.value);
    dispatch(selectedDictionaryReducer(event.target.value));
  }

  function loginNumber() {
    return (
      <div className="container">
        <ReCaptcha sitekey={Config.ReCaptchaSiteKey} action="submit" verifyCallback={verifyCallback} />

        <div className="row">
          <div className="col-sm leftSide">
            <div className="row justify-content-center">
              <img src={mainLogo6464} alt="Logo" className="login-logo" />
            </div>
            <div className="row justify-content-center">
              <div className="login-title">{dictionary?.loginPhoneNumber?.title}</div>
            </div>
            <div className="row justify-content-center">
              <div className="login-text" dangerouslySetInnerHTML={{ __html: dictionary?.loginOTP?.text.replace("{phoneNumber}", phoneNumber) }}></div>
            </div>
            <div className="row justify-content-center">
              <div
                className={!otpError ? "login-labelTop" : "login-otp-labelTopError"}
                dangerouslySetInnerHTML={{ __html: !otpError ? dictionary?.loginOTP?.otpTop : dictionary?.loginOTP?.otpTopError }}
              ></div>
              <div className="login-otp-inputContainer">
                <input
                  ref={otpInput}
                  className={!otpError ? "login-otp" : "login-otpError"}
                  type="number"
                  pattern="\d*"
                  id="otp"
                  name="otp"
                  maxLength={6}
                  onKeyDown={(event: any) => {
                    if (event.keyCode === 13 && event.target.value.length > 5) handleLoginOTPClick();
                  }}
                  onChange={handleChangeInput}
                  value={otpNumber}
                ></input>
              </div>
              <div className="login-labelBottom">
                {dictionary?.loginOTP?.otpBottomP1}{" "}
                <span id="resendButton" className="login-otp-labelBottomP2" onClick={handleLoginNumberClick}>
                  {dictionary?.loginOTP?.otpBottomP2}
                </span>
              </div>
            </div>
            <div className="row justify-content-center">
              <Button className="button login-confirm" 
                text={dictionary?.loginOTP?.confirmButton}
                disabled={confirmButtonDisabled}
                loading={confirmButtonLoading}
                loadingText={dictionary?.general?.buttons.validating}
                onClick={handleLoginOTPClick} />
            </div>
            <div className="row justify-content-center">
              <div className="col-sm login-changePhoneNumber" onClick={handleChangeNumberClick}>
                {dictionary?.loginOTP?.changePhoneNumber}
              </div>
            </div>
            <div className="row justify-content-center">
              <select ref={languageSelect} className="login-language" onChange={onChangeLanguage}>
                <option value="en">English</option>
                <option value="pt">Português</option>
              </select>
            </div>
            <LoginFooter/>
          </div>
          <div className="col-sm rightSide"></div>
        </div>
      </div>
    );
  }

  return <div className="App">{loginNumber()}</div>;
};

export default LoginOTP;
