import React from "react";
import mainLogoWhite from "../../assets/logos-vodafone-white.svg";
import mainLogoRed from "../../assets/logos-vodafone-red.svg";
import homeTopMenuBurgerWhite from "../../assets/ic-menu-white.svg";
import homeTopMenuBurgerRed from "../../assets/ic-menu-red.svg";
import "./homeTopMenu.scss";

interface HomeTopMenuProps {
  showWhite: boolean;
  onMenuClick: (data: any) => void;
  title: string;
}

export const HomeTopMenu: React.FC<HomeTopMenuProps> = ({ showWhite, onMenuClick, title }) => {
  function handleMenuClick() {
    onMenuClick(true);
  }

  function render() {
    const activeCSS = showWhite ? "container home-top-menu-white" : "container home-top-menu-red";
    return (
      <div id="homeTopMenu" className={activeCSS}>
        <div className="row">
          <div className="col-3 home-top-menu-left">
            <img src={showWhite ? mainLogoRed : mainLogoWhite} alt="Logo" className="home-top-menu-logo" />
          </div>
          <div className="col-6 home-top-menu-midle">{title}</div>
          <div className="col-3 home-top-menu-right">
            <img src={showWhite ? homeTopMenuBurgerRed : homeTopMenuBurgerWhite} alt="Logo" className="home-top-menu-burger" onClick={handleMenuClick} />
          </div>
        </div>
      </div>
    );
  }

  return render();
};

export default HomeTopMenu;
