import React from "react";
import ReactGA from "react-ga";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import Requests from "../../requests";
import Storage from "../../storage";
import iconExtrasArrow from "../../assets/icons-chevron-right-red.svg";
import { HomeTopMenu } from "./homeTopMenu";
import { HomeMenu } from "./homeMenu";
import { CategoriesList } from "../data/categoriesList";
import { DataCategory, Aux } from "../../models";
import { useMediaQuery } from "react-responsive";
import { format } from "date-fns";
import { pt } from "date-fns/locale";
import differenceInCalendarDay from "date-fns/differenceInCalendarDays";
import lastDayOfMonth from "date-fns/lastDayOfMonth";

import "./home.scss";
import TicketsList from "../tickets/ticketsList";
import TicketDetail from "../tickets/ticketDetail";
import Menu, { MenuItems } from "../home/menu";
import TicketDetailDesktop from "../tickets/ticketDetailDesktop";
import { Button } from "../../components/button";
import { ConfigUtils, Feature } from "../../models";

const TOP_CATEGORY_SIZE = 3;

export const Home: React.FC = () => {
  const history = useHistory();
  const [tickets, setTickets] = React.useState<[]>([]);
  const [selectedTicket, setSelectedTicket] = React.useState<any>(null);
  const [showTicketDetail, setShowTicketDetail] = React.useState<boolean>(false);
  const [ticketTypeList, setTicketTypeList] = React.useState<[any]>();
  const dictionary = useSelector((state: any) => state.selectedDictionary);
  const homeMiddleRed = React.useRef<HTMLDivElement>(null);
  const homeMiddleRedBottom = React.useRef<number>();
  const [topMenuWhite, setTopMenuWhite] = React.useState<boolean>(false);
  const [showMenu, setShowMenu] = React.useState<boolean>(false);
  const [totalSpent, setTotalSpent] = React.useState<number>(-1);
  const [dataCategoriesList, setDataCategoriesList] = React.useState<DataCategory[]>([]);
  const [loadingTickets, setLoadingTickets] = React.useState<boolean>();
  let dataCategoriesListFromServer = React.useRef<any[]>([]);
  const session = Storage.getSession();

  const isDesktop = useMediaQuery({ query: "(min-width: 600px)" });

  React.useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    let homeMiddleRed = window.document.getElementById("homeMiddleRed");
    let homeTopMenu = window.document.getElementById("homeTopMenu");
    if (homeMiddleRed && homeTopMenu) {
      let hmrBottom = homeMiddleRed.getBoundingClientRect().bottom;
      let htmBottom = homeTopMenu.getBoundingClientRect().bottom;
      homeMiddleRedBottom.current = hmrBottom - htmBottom;
    }
    ReactGA.pageview("/home");

    setLoadingTickets(true);
    Requests.getTicketsTypes()
      .then((responseTypes: any) => {
        setTicketTypeList(responseTypes.types);
        Requests.getTickets(Aux.addDays(new Date(), Config.HomeDaysBeforeTickets), new Date(), 0, Config.HomeMaxTickets, [])
          .then((response: any) => {
            setTickets(response.tickets);
            setLoadingTickets(false);
          })
          .catch((ex: any) => {
            Aux.handleErrors(history, ex, dictionary);
            setLoadingTickets(false);
          });
      })
      .catch((ex: any) => {
        Aux.setToastText(dictionary?.errors.generic);
        Aux.handleErrors(history, ex, dictionary);
      });

    if(ConfigUtils.isFeatureEnabled(Feature.DataStatement)) {
      Requests.getCategories()
        .then((response: any) => {
          let listCategories: DataCategory[] = [];
          let totalSpent = 0;
          dataCategoriesListFromServer.current = response.categories;
          response.categories.forEach((element: any) => {
            let tempCat = new DataCategory();
            tempCat.id = element.id;
            tempCat.name = element.name[Storage.getActiveLanguage()];
            listCategories.push(tempCat);
          });

          /* add Others category */
          let othersCat = new DataCategory();
          othersCat.id = "Others";
          othersCat.name = dictionary.data.categories.others;
          othersCat.dataUsed = 0;
          othersCat.dataLimit = 0;
          listCategories.push(othersCat);

          const dateNow = new Date(),
            y = dateNow.getFullYear(),
            m = dateNow.getMonth();

          Requests.getCategoriesUsage([], new Date(y, m, 1, 0, 0, 0), new Date())
            .then((response: any) => {
              response?.data_usage?.forEach((elementData: any) => {
                if (elementData.data_used) totalSpent += elementData.data_used;

                listCategories.forEach((elementCat) => {
                  if (elementData.category.id === elementCat.id) {
                    elementCat.selected = true;
                    elementCat.dataUsed = elementData.data_used;
                    elementCat.dataLimit = 0;
                  }
                });
                listCategories.sort((e1, e2) => (e2.dataUsed || 0) - (e1.dataUsed || 0));
              });
              setTotalSpent(totalSpent);
              setDataCategoriesList(listCategories.slice(0, TOP_CATEGORY_SIZE));
            })
            .catch((ex: any) => {
              Aux.handleErrors(history, ex, dictionary);
            });
        })
        .catch((ex: any) => {
          Aux.handleErrors(history, ex, dictionary);
        });
    }

    return () => document.body.removeEventListener("scroll", handleScroll);
  }, [history, dictionary]);   

  function handleScroll(event: any) {
    if (homeMiddleRedBottom.current && event.target.documentElement.scrollTop > homeMiddleRedBottom.current) setTopMenuWhite(true);
    else setTopMenuWhite(false);
  }

  function getGreeting(): string {
    let greeting = "";
    let now = new Date();
    if (now.getHours() >= 0 && now.getHours() < 5) greeting = dictionary?.home.greeting3;
    else if (now.getHours() >= 5 && now.getHours() < 12) greeting = dictionary?.home.greeting1;
    else if (now.getHours() >= 12 && now.getHours() < 18) greeting = dictionary?.home.greeting2;
    else greeting = dictionary?.home.greeting3;
    return greeting;
  }

  function renderHome() {
    let greeting = getGreeting();
    return (
      <div>
        <div>
          <HomeMenu show={showMenu} onCloseClick={setShowMenu}></HomeMenu>
        </div>
        <div>
          <TicketDetail typeList={ticketTypeList} ticket={selectedTicket} show={showTicketDetail} onCloseClick={setShowTicketDetail} />
        </div>
        <div>
          <HomeTopMenu showWhite={topMenuWhite} onMenuClick={setShowMenu} title=""></HomeTopMenu>
          <div className={"container" + (ConfigUtils.isFeatureEnabled(Feature.DataStatement) ? "" : " no-data-statement")}>
            <div className="row">
              <div className="home-top-greeting">{greeting}</div>
            </div>
            <div className="row">
              <div className="home-top-name">{session?.profile?.name}</div>
            </div>
            <div className="row">
              <div className="home-top-phoneNumber">+{session?.profile?.msisdn}</div>
            </div>
            <div id="homeMiddleRed" ref={homeMiddleRed} className="row home-middle-red"></div>
            <div className="row">
              {ConfigUtils.isFeatureEnabled(Feature.DataStatement) &&
                <div className="home-middle-plans">
                      <div className="row">
                        <svg width="100%" height="100%" viewBox="0 0 36 36" className="circular-chart">
                          <path className="circle-bg" d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831" />
                          <path className="circle" strokeDasharray={`${totalSpent <= 0 ? 0 : 30}, 100`} d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831" />
                          <text x={totalSpent >= 0 ? 15 : 18} y="19.35" textAnchor="end" className="percentage-top1">
                            {totalSpent >= 0 ? Aux.convertBytesValueOnly(totalSpent, 2) : "-"}
                          </text>
                          <text x={totalSpent >= 100 ? 27 : 25} y="19.35" className="percentage-top2">
                            &nbsp;&nbsp;&nbsp; {totalSpent >= 0 ? Aux.convertBytesUnitsOnly(totalSpent) : ""}
                          </text>
                          <text x="18" y="23.35" className="percentage-bottom">
                            {/* from 30.8 Gb */}
                          </text>
                        </svg>
                      </div>
                  <div className="home-middle-plans-info">
                    <div className="home-plan-title">{dictionary?.home.plan}</div>
                    <div className="home-plan-text">{session?.profile?.tariff?.name}</div>
                    <div className="home-plan-subtext">{dictionary?.home.renews.replace("{days}", Math.abs(differenceInCalendarDay(new Date(), lastDayOfMonth(new Date()))) + 1)}</div>
                  </div>
                </div>
              }
            </div>
            {
              ConfigUtils.isFeatureEnabled(Feature.DataStatement) &&
                <div className="row">
                  <div className="row">
                    <CategoriesList items={dataCategoriesList} totalSpent={totalSpent} categoriesText={dictionary?.data?.categories.text} source="home"></CategoriesList>
                  </div>
                </div>
            }
            {/********************************* TICKETS *************************/}
            <div className="row home-tickets-top">
              <div className="home-tickets-title">{dictionary?.tickets.title}</div>
              <Button className="secondary-button home-tickets-new" text={dictionary?.tickets.newTicket} onClick={() => history.push("/newTicketType")}></Button>
            </div>
            <div className="row home-tickets-middle">
              <div className="home-tickets-text">{dictionary?.home.ticketText}</div>
              <div className="home-tickets-all" onClick={() => history.push("/tickets")}>
                {dictionary?.home.ticketAll}
              </div>
            </div>
            <div className="row home-tickets-items">
              <TicketsList
                typeList={ticketTypeList as [any]}
                tickets={tickets}
                loading={loadingTickets}
                loadingItemCount={Config.HomeMaxTickets}
                onItemClick={(ticket: any) => {
                  setSelectedTicket(ticket);
                  setShowTicketDetail(true);
                }}
              ></TicketsList>
            </div>
            {/********************************* EXTRAS *************************/}

            <div className="row home-extras-top">
              <div className="home-extras-title">{dictionary?.home.extrasTitle}</div>
            </div>
            <div className="row home-extras-middle">
              <div className="home-extras-text" onClick={() => window.open(Config.PrivacyPolicyLink, "_blank")}>
                {dictionary?.general.privacyPolicy.label}
              </div>
              <img src={iconExtrasArrow} alt="Logo" className="home-extras-arrow" />
              <div className="home-extras-separator"></div>
            </div>
            <div className="row home-extras-middle">
              <div className="home-extras-text" onClick={() => window.open(Config.TermsAndConditionsLink, "_blank")}>
                {dictionary?.general.termsAndConditions.label}
              </div>
              <img src={iconExtrasArrow} alt="Logo" className="home-extras-arrow" />
              <div className="home-extras-separator"></div>
            </div>
            <div className="row home-extras-middle">
              <div className="home-extras-text" onClick={() => window.open(Config.FAQLink, "_blank")}>
                {dictionary?.general.helpFAQs}
              </div>
              <img src={iconExtrasArrow} alt="Logo" className="home-extras-arrow" />
              <div className="home-extras-separator"></div>
            </div>
            <br />
          </div>
        </div>
      </div>
    );
  }

  function renderDesktop() {
    let greeting = getGreeting();
    return (
      <div className="container">
        <div className={showTicketDetail ? "tickets-detail-back-container-show" : "tickets-detail-back-container-hide"}></div>
        <div className="row main-row">
          <div className="support-dataleft-div">
            <Menu selected={MenuItems.Home} />
          </div>
          <div className={showTicketDetail ? "support-data-main-div-small" : "support-data-main-div-full"}>
            <div className="container">
              <div className="row justify-content-center">
                <div className="home-greeting-text">
                  <div className="home-greeting-text-name">
                    {greeting}, <b>{session?.profile?.name}</b>
                  </div>
                  {dictionary?.type === "pt" ? (
                    <div className="home-greeting-text-date">{format(new Date(), "iiii, dd MMMM yyyy", { locale: pt })}</div>
                  ) : (
                    <div className="home-greeting-text-date">{format(new Date(), "iiii, dd MMMM yyyy")}</div>
                  )}
                </div>
              </div>
              <div className="row justify-content-center">
                <div className="home-middle-plans">
                  <div className="row">
                    {
                      ConfigUtils.isFeatureEnabled(Feature.DataStatement) &&
                        <div className="home-middle-plans-chart">
                          <svg viewBox="0 0 36 36" className="circular-chart">
                            <path className="circle-bg" d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831" />
                            <path className="circle" strokeDasharray={`${totalSpent <= 0 ? 0 : 30}, 100`} d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831" />
                            <text x={totalSpent >= 0 ? 15 : 18} y="19.35" textAnchor="end" className="percentage-top1">
                              {totalSpent >= 0 ? Aux.convertBytesValueOnly(totalSpent, 2) : "-"}
                            </text>
                            <text x={totalSpent >= 100 ? 27 : 25} y="19.35" className="percentage-top2">
                              {totalSpent >= 0 ? Aux.convertBytesUnitsOnly(totalSpent) : ""}
                            </text>
                            <text x="18" y="23.35" fontSize="0.2em" className="percentage-bottom">
                              {/* from 30.8 Gb */}
                            </text>
                          </svg>
                        </div>      
                    }
                  
                    <div className="home-middle-info">
                      <div className="home-middle-number-info">
                        <div className="home-number-title">{dictionary?.home.number}</div>
                        <div className="home-number-text">+{session?.profile?.msisdn}</div>
                      </div>
                      { ConfigUtils.isFeatureEnabled(Feature.DataStatement) && 
                        <div className="home-middle-plans-info">
                          <div className="home-plan-title">{dictionary?.home.plan}</div>
                          <div className="home-plan-text">{session?.profile?.tariff?.name}</div>
                          <div className="home-plan-subtext">{dictionary?.home.renews.replace("{days}", Math.abs(differenceInCalendarDay(new Date(), lastDayOfMonth(new Date()))) + 1)}</div>
                        </div>
                      }
                    </div>
                  </div>
                  {
                    ConfigUtils.isFeatureEnabled(Feature.DataStatement) &&
                      <div className="row home-categories">
                        <CategoriesList items={dataCategoriesList} totalSpent={totalSpent} categoriesText={dictionary?.data?.categories.text} source="home"></CategoriesList>
                      </div>
                  }
                </div>
              </div>
              {/********************************* TICKETS *************************/}
              <div className="row justify-content-center">
                <div className="home-tickets-container">
                  <div className="row home-tickets-top">
                    <div className="home-tickets-title">{dictionary?.tickets.title}</div>
                    <Button className="secondary-button home-tickets-new" text={dictionary?.tickets.newTicket} onClick={() => history.push("/newTicketType")}></Button>
                  </div>
                  <div className="row home-tickets-middle">
                    <div className="home-tickets-text">{dictionary?.home.ticketText}</div>
                    <div className="home-tickets-all" onClick={() => history.push("/tickets")}>
                      {dictionary?.home.ticketAll}
                    </div>
                  </div>
                  <div className="row home-tickets-items">
                    <TicketsList
                      typeList={ticketTypeList as [any]}
                      tickets={tickets}
                      loading={loadingTickets}
                      loadingItemCount={Config.HomeMaxTickets}
                      onItemClick={(ticket: any) => {
                        setSelectedTicket(ticket);
                        setShowTicketDetail(true);
                      }}
                    />
                  </div>
                </div>
              </div>
              {/********************************* EXTRAS *************************/}
              <div className="row justify-content-center">
                <div className="home-extras-container">
                  <div className="row home-extras-top">
                    <div className="home-tickets-title">{dictionary?.home.extrasTitle}</div>
                  </div>
                  <div className="row home-extras-middle">
                    <div className="home-extras-text" onClick={() => window.open(Config.PrivacyPolicyLink, "_blank")}>
                      {dictionary?.general.privacyPolicy.label}
                    </div>
                    <img src={iconExtrasArrow} alt="Logo" className="home-extras-arrow" />
                    <div className="home-extras-separator"></div>
                  </div>
                  <div className="row home-extras-middle">
                    <div className="home-extras-text" onClick={() => window.open(Config.TermsAndConditionsLink, "_blank")}>
                      {dictionary?.general.termsAndConditions.label}
                    </div>
                    <img src={iconExtrasArrow} alt="Logo" className="home-extras-arrow" />
                    <div className="home-extras-separator"></div>
                  </div>
                  <div className="row home-extras-middle">
                    <div className="home-extras-text" onClick={() => window.open(Config.FAQLink, "_blank")}>
                      {dictionary?.general.helpFAQs}
                    </div>
                    <img src={iconExtrasArrow} alt="Logo" className="home-extras-arrow" />
                    <div className="home-extras-separator"></div>
                  </div>
                  <br />
                </div>
              </div>
            </div>
          </div>
          <div className={showTicketDetail ? "tickets-detail-container-show" : "tickets-detail-container-hide"}>
            <TicketDetailDesktop typeList={ticketTypeList} ticket={selectedTicket} onCloseClick={setShowTicketDetail} />
          </div>
        </div>
      </div>
    );
  }

  function render(this: any) {
    return isDesktop ? renderDesktop() : renderHome();
  }

  return <div className="App">{render()}</div>;
};

export default Home;
