
import { combineReducers } from "redux";
import { dictionary } from "../../assets/texts.js";
import Storage from "./../../storage";

const selectedDictionaryReducer = (selectedDictionary = (Storage.getActiveLanguage() === "pt" ? dictionary.pt : dictionary.en), action) => {
    if (action.type === "DICTIONARY_SELECTED") return action.payload;
    else return selectedDictionary;
}

const toastTextReducer = (text = "", action) => {
    if (action.type === "TOAST_TEXT") return action.payload;
    else return text;
}

const showLogoutReducer = (show = false, action) => {
    if (action.type === "SHOW_LOGOUT") return action.payload;
    else return show;
}


export default combineReducers({
    selectedDictionary: selectedDictionaryReducer,
    toastText: toastTextReducer,
    showLogout: showLogoutReducer
});