import React from "react";
import mainLogo6464 from "../../assets/logo6464.png";
import "./loginBlocked.scss";
import { useHistory, useParams } from "react-router-dom";
import useCountDown from "react-countdown-hook";
import Storage from "../../storage";
import { selectedDictionaryReducer } from "../../store/actions";
import { useDispatch, useSelector } from "react-redux";

import { LoginFooter } from "./loginFooter";

interface ParamTypes {
  type: string;
  blockedtimeleft: string;
}

export const LoginBlocked: React.FC = () => {
  const confirmButton = React.useRef<HTMLButtonElement>(null);
  const dictionary = useSelector((state: any) => state.selectedDictionary);
  const dispatch = useDispatch();
  const history = useHistory();
  const { type, blockedtimeleft } = useParams<ParamTypes>();
  const phoneNumberLogin = Storage.getPhoneNumberLogin();
  const languageSelect = React.useRef<HTMLSelectElement>(null);
  const [timeLeft, { start }] = useCountDown(Number(blockedtimeleft) * 1000 * 1, 1000);

  React.useEffect(() => {
    if (languageSelect.current) languageSelect.current.value = Storage.getActiveLanguage() as string;
    window.history.pushState(null, "", window.location.pathname);
    window.onpopstate = (e: any) => {
      history.goBack();
    };
    start();
  }, [history, start]);

  React.useEffect(() => {
    if (timeLeft === 0 && confirmButton.current) confirmButton.current.disabled = false;
    else if (confirmButton.current) confirmButton.current.disabled = true;
  }, [timeLeft]);

  function onChangeLanguage(event: any) {
    Storage.setActiveLanguage(event.target.value);
    dispatch(selectedDictionaryReducer(event.target.value));
  }

  function handleConfirmClick() {
    if (type === "phonenumber") {
      history.push("/loginwait/phonenumber");
    }
    if (type === "otp") {
      history.push("/loginwait/phonenumber");
    }
  }

  function handleChangeNumberClick() {
    history.push("/loginphonenumber");
  }

  function pad(i: number) {
    return ("0" + i).slice(-2);
  }

  function render() {
    return (
      <div className="container">
        <div className="row">
          <div className="col-sm leftSide">
            <div className="row justify-content-center">
              <img src={mainLogo6464} alt="Logo" className="login-logo" />
            </div>
            <div className="row justify-content-center">
              <div className="login-title">{dictionary?.loginPhoneNumber?.title}</div>
            </div>
            <div className="row justify-content-center">
              <div className="login-text" dangerouslySetInnerHTML={{ __html: dictionary?.loginBlocked?.text.replace("{phoneNumber}", "+" + Config.PhoneNumberCountryCode + phoneNumberLogin) }}></div>
            </div>
            <div className="row justify-content-center">
              <div className="login-blocked-timer">
                {timeLeft > 0 ? (
                  <span>
                    {pad(Math.floor(timeLeft / 60000))}:{pad((timeLeft % 60000) / 1000)}
                  </span>
                ) : (
                  <div className="login-blocked-resendtext">{dictionary?.loginBlocked?.resendText}</div>
                )}
              </div>
            </div>
            <div className="row justify-content-center">
              <button ref={confirmButton} className="button  login-confirm" onClick={handleConfirmClick}>
                {dictionary?.loginBlocked?.button}
              </button>
            </div>
            <div className="row justify-content-center">
              <div className="login-changePhoneNumber" onClick={handleChangeNumberClick}>
                {dictionary?.loginOTP?.changePhoneNumber}
              </div>
            </div>
            <div className="row justify-content-center">
              <select ref={languageSelect} className="login-language" onChange={onChangeLanguage}>
                <option value="en">English</option>
                <option value="pt">Português</option>
              </select>
            </div>
            <LoginFooter/>
          </div>
          <div className="col-sm rightSide"></div>
        </div>
      </div>
    );
  }

  return <div className="App">{render()}</div>;
};

export default LoginBlocked;
