import React from "react";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";

import mainLogoRed from "../../assets/logos-vodafone-red.svg";
import menuCloseWhite from "../../assets/icons-close-red.svg";
import ticketSuccess from "../../assets/illustrations-actions-success.svg";
import menuBack from "../../assets/icons-arrow-arrow-left.svg";
import "./newTicket.scss";

import { useHistory, useParams } from "react-router-dom";
import Storage from "../../storage";
import Requests from "../../requests";
import Menu, { MenuItems } from "../home/menu";
import { Button } from "../../components/button";
import { Aux } from "../../models";
import { TicketType, itemValue, PersonalDataItem } from "../../model/tickets";

const STATUS_SUCCESS = "success";

interface ParamTypes {
  status: string;
}

export const NewTicketConfirm: React.FC = () => {
  const { status } = useParams<ParamTypes>();
  const [showSuccess, setShowSuccess] = React.useState<Boolean>(status === STATUS_SUCCESS);
  const [requestId, setRequestId] = React.useState<string>(Storage.getNewTicketRequestId() || "");
  const [type, setType] = React.useState<string>("");
  const [ticketItem, setItem] = React.useState<PersonalDataItem | null>(null);
  const [email, setEmail] = React.useState<string>("");
  const [range, setRange] = React.useState<string>("");
  const [subject, setSubject] = React.useState<string>("");
  const [description, setDescription] = React.useState<string>("");
  const [sendButtonLoading, setSendButtonLoading] = React.useState<boolean>(false);
  const [continueButtonDisabled, setContinueButtonDisabled] = React.useState<boolean>(!showSuccess);
  const dictionary = useSelector((state: any) => state.selectedDictionary);
  const isDesktop = useMediaQuery({ query: "(min-width: 600px)" });
  const history = useHistory();

  React.useEffect(() => {
    setItem(Storage.getNewTicketItem() as PersonalDataItem);
    setEmail(Storage.getNewTicketEmail() as string);
    setRange(Storage.getNewTicketRange() as string);
    setSubject(Storage.getNewTicketSubject() as string);
    setDescription(Storage.getNewTicketDescription() as string);
    window.history.pushState(null, "", window.location.pathname);
    window.onpopstate = (e: any) => {
      history.goBack();
    };
  }, [history]);

  function handleCloseClick() {
    history.push("/tickets");
  }

  function handleContinueClick() {
    setSendButtonLoading(true);
    let initialGroup = Config.TicketInitialGroup;
    let type = Storage.getNewTicketType() as string;
    let item = Storage.getNewTicketItem() as PersonalDataItem;
    let itemLabel = itemValue(item);
    let summary = itemLabel;
    let requestDescription = "";
    switch (type) {
      case TicketType.PersonalData:
        let email = Storage.getNewTicketEmail() as string;
        let range = Storage.getNewTicketRange() as string;
        let description = Storage.getNewTicketDescription() as string;
        let phoneNumber = Storage.getPhoneNumberLogin() as string;

        summary = dictionary?.tickets.personalData[item].label;

        requestDescription = dictionary?.tickets?.personalData[item].template;
      
        if (requestDescription) {
          requestDescription = requestDescription
            .replace("{phoneNumber}", phoneNumber)
            .replace("{initialGroup}", initialGroup)
            .replace("{type}", type)
            .replace("{item}", itemValue(item))
            .replace("{summary}", summary)
            .replace("{email}", email)
            .replace("{range}", range)
            .replace("{description}", description);
        }
        break;
      case TicketType.ProductsAndServices:
      default:
        requestDescription = Storage.getNewTicketDescription() as string;
        break;
    }

    Requests.newTicket(initialGroup, type, itemLabel, summary, requestDescription)
      .then((response: any) => {
        setRequestId(response.ticketId);
        setType(type);
        setShowSuccess(true);
        process.nextTick(() => {
          setContinueButtonDisabled(false);
        });
        Storage.setNewTicketRequestId("");
        Storage.setNewTicketType("");
        Storage.setNewTicketTypeObject(null);
        Storage.setNewTicketEmail("");
        Storage.setNewTicketSubject("");
        Storage.setNewTicketRange("");
        Storage.setNewTicketDescription("");
      }).catch((ex: any) => {
        setSendButtonLoading(false);

        let reason = ex.response?.data?.reason;
        let errorMessage = dictionary?.errors.generic;
        if (reason && dictionary?.tickets.errors[reason]) {
          errorMessage = dictionary?.tickets.errors[reason];
        }
        Aux.setToastText(errorMessage);
        Aux.handleErrors(history, ex, dictionary);
      });
  }

  function handleSuccessContinueClick() {
    history.push("/home");
  }

  function handleChangeAddress() {
    history.goBack();
  }

  function getTitle() {
    const type = Storage.getNewTicketType() as string;
    if(type === TicketType.ProductsAndServices) {
      return dictionary?.tickets?.newTicketPS.productsServices;
    }

    const subject = Storage.getNewTicketSubject() as string;
    let title = subject ? dictionary?.tickets?.newTicketSubject[Aux.camelize(subject)] : undefined;
    return isDesktop && title ? title : dictionary?.tickets?.newTicketConfirmation?.text;
  }

  function getSuccessTitle(type: string, subject: string) {
    if(type === TicketType.ProductsAndServices) {
      return dictionary?.tickets?.newTicketPS.productsServices;
    }
    let title = subject ? dictionary?.tickets?.newTicketSubject[Aux.camelize(subject)] : undefined;
    return isDesktop && title ? title : dictionary?.tickets?.newTicketConfirmation?.text;
  }

  function getRangeLabel(range: string) {
    const rangeMap = JSON.parse(dictionary.tickets.newTicketRange?.rangueValues) as {value: string, label: string}[];
    const rangeEntry = rangeMap.filter(e => e.value === range);
    return rangeEntry && rangeEntry.length > 0 ? rangeEntry[0].label : undefined;
  }

  function getProductsAndServicesSubject(subject: string) {
    const subjectMap = JSON.parse(dictionary.tickets.newTicketPS?.subjectValues) as {value: string, label: string}[];
    const subjectEntry = subjectMap.filter(e => e.value === subject);
    return subjectEntry && subjectEntry.length > 0 ? subjectEntry[0].label : undefined;
  }

  function renderConfirmation() {
    let type = Storage.getNewTicketType() as string;
    switch (type) {
      case TicketType.PersonalData:
        return (
          <div>
            {isDesktop ?
              <div className="row justify-content-center">
                <img
                  src={menuBack}
                  alt="Logo"
                  className="newticket-top-menu-back"
                  onClick={() => {
                    history.goBack();
                  }}
                />
              </div>
              : ""}
            <div className="row justify-content-center">
              <div className="newticket-text">{getTitle()}</div>
            </div>
            <div className="row justify-content-center">
              { isDesktop ? 
              <div className="newticket-subText"
                dangerouslySetInnerHTML={{ __html: dictionary?.tickets?.newTicketConfirmation?.subText.replace("{email}", Storage.getNewTicketEmail())}}>
              </div>
              : null}
            </div>
            <div className="row justify-content-center">
              <div className="newticket-confirm-container">
                <div className="row newticket-confirm-label">{dictionary?.tickets?.newTicketEmail?.text}</div>
                <div className="row newticket-confirm-value">{email}</div>
                {type === TicketType.PersonalData && ticketItem === PersonalDataItem.CallsStatement ? (
                  <div>
                    <div className="row newticket-confirm-label">{dictionary?.tickets?.newTicketRange?.text}</div>
                    <div className="row newticket-confirm-value">
                      {getRangeLabel(range)}
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {type === TicketType.PersonalData && ticketItem === PersonalDataItem.DataAccess ?
                  <div>
                    <div className="row newticket-confirm-label">{dictionary?.tickets.personalData.dataAccess.summaryLabel}</div>
                    <div className="row newticket-confirm-value">
                      {Storage.getNewTicketDescription()}
                    </div>
                </div>
                : ""}
              </div>
            </div>
            <div className="row justify-content-center">
              <Button className="button newTicket-confirm-sendButton"
                text={dictionary?.general?.buttons?.submit}
                disabled={false}
                loading={sendButtonLoading}
                onClick={handleContinueClick}>
              </Button>
            </div>
            {isDesktop ? (
              ""
            ) : (
              <div className="row justify-content-center">
                <div className="newticket-confirm-continueButton-bottom" onClick={handleChangeAddress}>
                  {dictionary?.tickets?.newTicketConfirmation?.changeEmailAddress}
                </div>
              </div>
            )}
          </div>
        );
      case TicketType.ProductsAndServices:
      default:
        return (
          <div>
            {isDesktop ?
              <div className="row justify-content-center">
                <img
                  src={menuBack}
                  alt="Logo"
                  className="newticket-top-menu-back"
                  onClick={() => {
                    history.goBack();
                  }}
                />
              </div>
              : ""}
            {isDesktop ?
              <div className="row justify-content-center">
                <div className="newticket-text">{getTitle()}</div>
              </div>
              : ""}
            <div className="row justify-content-center">
              <div className="newticket-confirm-container">
                <div className="row newticket-confirm-label">{dictionary?.tickets?.newTicketSubject?.text}</div>
                <div className="row newticket-confirm-value">{getProductsAndServicesSubject(Storage.getNewTicketSubject() as string)}</div>
                <div className="row newticket-confirm-label">{dictionary?.tickets?.newTicketPS?.message}</div>
                <div className="row newticket-confirm-value">{description}</div>
              </div>
            </div>
            <div className="row justify-content-center">
              <Button className="button newTicket-confirm-sendButton" 
                text={dictionary?.general?.buttons?.submit}
                disabled={false}
                loading={sendButtonLoading}
                onClick={handleContinueClick}>
              </Button>
            </div>
          </div>
        );
    }
  }

  function renderSuccess() {
    return (
      <div>
        { isDesktop ?
            <div className="row justify-content-center">
              <div className="newticket-text">{getSuccessTitle(type, subject)}</div>
            </div>
            : null}
        <div className="row justify-content-center">
          <img src={ticketSuccess} alt="Logo" className="newticket-success-img" />
        </div>
        <div className="row justify-content-center">
          <div className="newticket-success-text" dangerouslySetInnerHTML={{ __html: dictionary?.tickets?.newTicketConfirmation?.successText.replace("{id}", parseInt(requestId, 10)) }}></div>
        </div>
        <div className="row justify-content-center">
          <div className="newticket-success-subtext" dangerouslySetInnerHTML={{ __html: dictionary?.tickets?.newTicketConfirmation?.successSubText }}></div>
        </div>
        <div className="row justify-content-center">
          <Button className="button newTicket-confirm-continueButton" 
            text={dictionary?.general?.buttons?.continue}
            disabled={continueButtonDisabled}
            loading={false}
            onClick={handleSuccessContinueClick}>
          </Button>
        </div>
      </div>
    );
  }

  function renderMobile() {
    return (
      <div className="container">
        <div className="row">
          <div className="newticket-top-menu-left">
            <img src={mainLogoRed} alt="Logo" className="newticket-top-menu-logo" />
          </div>
          <div className="newticket-top-menu-midle">{dictionary?.tickets?.newTicketType?.title}</div>
          <div className="newticket-top-menu-right">
            <img src={menuCloseWhite} alt="Logo" className="newticket-top-menu-close" onClick={handleCloseClick} />
          </div>
        </div>
        {!showSuccess ? renderConfirmation() : renderSuccess()}
      </div>
    );
  }

  function renderDesktop() {
    return (
      <div className="container">
        <div className="row main-row">
          <div className="support-data-left-div">
            <Menu selected={MenuItems.Tickets}></Menu>
          </div>
          <div className="support-data-main-div-full">
            <div className="container">{!showSuccess ? renderConfirmation() : renderSuccess()}</div>
          </div>
        </div>
      </div>
    );
  }

  function render() {
    return isDesktop ? renderDesktop() : renderMobile();
  }

  return <div className="App">{render()}</div>;
};

export default NewTicketConfirm;
